import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ActionModal } from "components";
import {
  useActionModalGetContext,
  useActionModalSetContext,
} from "context/ActionModalProvider";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";

const ActionModalContainer = ({ selectedFields }) => {
  const { isOpen } = useActionModalGetContext();
  const { openActionModal, closeActionModal } = useActionModalSetContext();

  // Initially, if redirected from a notification with actionId param, open the modal
  const [searchParams] = useSearchParams();
  useEffect(() => {
    // If a single action trying to be opened from url, open the modal
    if (selectedFields?.length === 1) {
      const actionId = searchParams.get("actionId");
      const field = selectedFields[0];
      if (actionId) {
        openActionModal({
          type: ACTION_VIEW_MODE.view,
          shouldHideStatusToggle: true,
          actionId,
          fieldId: field.id,
          fieldInfo: field,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFields, searchParams]);

  return (
    isOpen && (
      <ActionModal
        isOpen={isOpen}
        selectedFields={selectedFields}
        onClose={closeActionModal}
      />
    )
  );
};

export default ActionModalContainer;
