import INDICES from "../../constants/indices";
import axiosInstance from "../utils/axiosInstance";
import { URLS } from "../utils/baseUrls";
import { blobToBase64Url, fetchBlob } from "../utils/fetchBlob";

const getUserFields = () => {
  // const config = {  userId };
  // return axios.get("/field_list/", config);
  return axiosInstance.get(`${URLS.fields}/`);
};

const getOtherFields = () => {
  return axiosInstance.get(`${URLS.fieldList}/`);
};

const getSelectedField = ({ fieldId }) => {
  return axiosInstance.get(`${URLS.fields}/${fieldId}/`);
};

const postFields = (postData) => {
  return axiosInstance.post(`${URLS.fields}/`, postData);
};

const updateField = (fieldId, postData) =>
  axiosInstance.patch(`${URLS.fields}/${fieldId}/`, postData);

const deleteField = (fieldId) =>
  axiosInstance.delete(`${URLS.fields}/${fieldId}/`);

const removeUserFromField = (fieldId, email) =>
  axiosInstance.post(`${URLS.fields}/${fieldId}/delete_user/`, { email });

const removeMeFromField = (fieldId) =>
  axiosInstance.post(`${URLS.fields}/${fieldId}/delete_me/`);

const getPruningChoises = ({ fieldId }) => {
  return axiosInstance.get(
    `${URLS.externalRequests}/actions-performed/get_pruning_choices/?field_id=${fieldId}`
  );
};

const getSoilGridData = (fieldId) =>
  axiosInstance.get(`${URLS.externalRequests}/soilgrids/?field_id=${fieldId}`);

const getIndiceMetaData = ({
  fieldId,
  startDate,
  endDate,
  indices,
  signal,
}) => {
  const params = new URLSearchParams();
  params.append("field_id", fieldId);
  params.append("start_date", startDate);
  params.append("end_date", endDate);
  if (!indices || Array.isArray(indices)) {
    params.append("indices", Object.values(INDICES).join(","));
  } else {
    params.append("indices", indices);
  }

  return axiosInstance.get(`${URLS.externalRequests}/satellite/`, {
    params,
    signal,
  });
};

const getIndicePng = (indice, fieldId, filename, filetype, callback) => {
  fetchBlob(
    `${URLS.externalRequests}/satellite-images/?indice=${indice}&field_id=${fieldId}&filename=${filename}&filetype=${filetype}`,
    (blob) => {
      if (typeof callback === "function") {
        callback(blobToBase64Url(blob));
      }
    }
  );
};

const getEquipments = (fieldId) => {
  return axiosInstance.get(`${URLS.fields}/${fieldId}/get_equipments/`);
};

export const fieldsApi = {
  create: postFields,
  delete: deleteField,
  fetchEquipments: getEquipments,
  fetchMy: getUserFields,
  fetchOne: getSelectedField,
  fetchOther: getOtherFields,
  fetchPruningChoises: getPruningChoises,
  indicesMeta: getIndiceMetaData,
  indicePng: getIndicePng,
  removeMe: removeMeFromField,
  removeUser: removeUserFromField,
  soilGrid: getSoilGridData,
  update: updateField,
};
