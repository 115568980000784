import { ACTION, OPERATIONS } from "constants/actions";

export const populateActionChoicesActions = (actions = []) => ({
  [ACTION.watering]: actions?.[0],
  [ACTION.drug]: actions?.[1],
  [ACTION.fertilize]: actions?.[2],
  [ACTION.diagnose]: actions?.[3],
  [ACTION.other]: actions?.[4],
});

export const populateActionChoicesTypes = (actionTypes = []) => ({
  prevention: {
    id: actionTypes[0][0],
    name: actionTypes[0][1],
  },
  treatment: {
    id: actionTypes[1][0],
    name: actionTypes[1][1],
  },
});

const populateWateringOperations = (operations = []) => ({
  [OPERATIONS.WATERING.SPRINKLERS]: operations?.[0],
  [OPERATIONS.WATERING.SUB_SURFACE_IRRIGATION]: operations?.[1],
  [OPERATIONS.WATERING.DROP_WATERING]: operations?.[2],
  [OPERATIONS.WATERING.ARTIFICIAL_RAIN]: operations?.[3],
  [OPERATIONS.WATERING.DROP_MINI_SPRINKLERS]: operations?.[4],
  [OPERATIONS.WATERING.SURFACE_IRRIGATION]: operations?.[5],
  [OPERATIONS.WATERING.OTHER]: operations?.[6],
});

const populateFertilizeOperations = (operations = []) => ({
  [OPERATIONS.FERTILIZE.HYDROLUBRICATION]: operations?.[0],
  [OPERATIONS.FERTILIZE.SOIL_BASIC]: operations?.[1],
  [OPERATIONS.FERTILIZE.SOIL_SURFACE]: operations?.[2],
  [OPERATIONS.FERTILIZE.FOLIAR]: operations?.[3],
});

const populateDiagnoseOperations = (operations = []) => ({
  [OPERATIONS.DIAGNOSE.ON_SITE_TESTING]: operations?.[0],
  [OPERATIONS.DIAGNOSE.SOIL_SAMPLING]: operations?.[1],
  [OPERATIONS.DIAGNOSE.LEAF_ANALYSIS]: operations?.[2],
  [OPERATIONS.DIAGNOSE.INSECT_MONITORING]: operations?.[3],
  [OPERATIONS.DIAGNOSE.LAB]: operations?.[4],
});

const populateOtherOperations = (operations = []) => ({
  [OPERATIONS.OTHER.PLOUGHING]: operations?.[0],
  [OPERATIONS.OTHER.GATHERING]: operations?.[1],
  [OPERATIONS.OTHER.ERADICATION]: operations?.[2],
  [OPERATIONS.OTHER.PRUNNING]: operations?.[3],
  [OPERATIONS.OTHER.SUN_SOLARIZATION]: operations?.[4],
  [OPERATIONS.OTHER.REPRODUCTIVE_MATERIAL]: operations?.[5],
  [OPERATIONS.OTHER.SOWING]: operations?.[6],
  [OPERATIONS.OTHER.PLANTING]: operations?.[7],
  [OPERATIONS.OTHER.STIRRING]: operations?.[8],
  [OPERATIONS.OTHER.SILAGE]: operations?.[9],
  [OPERATIONS.OTHER.BUNDLING]: operations?.[10],
  [OPERATIONS.OTHER.WRAPPING]: operations?.[11],
});

export const populateActionChoicesOperations = (actionChoices = []) => {
  const wateringOperations = populateWateringOperations(
    actionChoices?.[ACTION.watering]?.operations
  );
  const fertilizeOperations = populateFertilizeOperations(
    actionChoices?.[ACTION.fertilize]?.operations
  );
  const diagnoseOperations = populateDiagnoseOperations(
    actionChoices?.[ACTION.diagnose]?.operations
  );
  const otherOperations = populateOtherOperations(
    actionChoices?.[ACTION.other]?.operations
  );

  return {
    ...wateringOperations,
    ...fertilizeOperations,
    ...diagnoseOperations,
    ...otherOperations,
  };
};
