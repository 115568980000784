import React from "react";
import { NavLink } from "react-router-dom";
import { IconButton, useTheme } from "@mui/material";
import add from "../../assets/icons/add.icon.svg";

const AddButton = ({ navTo }) => {
  const theme = useTheme();
  return (
    <NavLink to={navTo}>
      <IconButton
        sx={{
          padding: "0.5rem",
          cursor: "pointer",

          backgroundColor: theme.color.white100,
          borderRadius: "0.5rem",
          ":hover": {
            backgroundColor: theme.color.hovered,
            boxShadow: `0px 2px 2px ${theme.color.black30}`,
          },
        }}
      >
        <img width={"20px"} alt={"AddButton"} src={add} />
      </IconButton>
    </NavLink>
  );
};

export default AddButton;
