import { useEffect, useState } from "react";
import { useSpring, animated as a } from "react-spring";
import { useTheme } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import SearchIcon from "@mui/icons-material/Search";
import { Title } from "components";
import "./SideBar.css";
import "../../assets/styles/animation.css";

const SidebarTitle = ({ children, style, className }) => {
  const theme = useTheme();

  return (
    <Title
      style={{
        color: theme.color.sidebarTitle,
        ...style,
      }}
      className={`sidebar-title ${className}`}
    >
      {children}
    </Title>
  );
};

const SideBarContainer = ({
  children,
  className,
  shouldAllowSideBarToggle = false,
  onOpen,
  isOpen,
  side = "left",
  style = {},
  wrapperStyle = {},
  size = "md",
}) => {
  const theme = useTheme();

  let width = theme.width.sidebar;
  if (size === "lg") {
    width = theme.width.drawer;
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(
    typeof isOpen !== "undefined" ? isOpen : true
  );

  const toggleDrawer = () => {
    if (typeof onOpen === "function") {
      onOpen(!isSidebarOpen);
    } else {
      setIsSidebarOpen((prev) => !prev);
    }
  };

  useEffect(() => {
    if (typeof isOpen !== "undefined") {
      setIsSidebarOpen(isOpen);
    }
  }, [isOpen]);

  const animated = useSpring({
    from: {
      ...(side === "left"
        ? { marginLeft: isSidebarOpen ? 0 : `-${width}` }
        : { marginRight: isSidebarOpen ? 0 : `-${width}` }),
      opacity: 1,
      zIndex: theme.zIndex.sidebar,
      height: "100%",
    },
    to: {
      ...(side === "left"
        ? { marginLeft: isSidebarOpen ? 0 : `-${width}` }
        : { marginRight: isSidebarOpen ? 0 : `-${width}` }),
      opacity: 1,
      zIndex: theme.zIndex.sidebar,
      height: "100%",
    },
    config: {
      mass: 1,
      tension: 700,
      friction: 50,
    },
  });

  return (
    <a.div
      style={{ ...animated, ...wrapperStyle }}
      className="camvio-smooth-animated-3"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: width,
          // height: calc(100vh - ${theme.width.navbar}),
          height: "100%",
          paddingTop: "16px",
          ...(side === "left" ? {} : { boxShadow: theme.boxShadow.left }),
          backgroundColor: theme.color.white100,
          borderRightWidth: side === "left" ? "1px" : "0px",
          borderLeftWidth: side !== "left" ? "1px" : "0px",
          borderColor: "#e1e1e1",
          ...style,
        }}
        className={className}
      >
        {children}
        {shouldAllowSideBarToggle && (
          <button className="toggle_sidebar_btn" onClick={toggleDrawer}>
            {isSidebarOpen ? (
              <KeyboardArrowLeftIcon fontSize="medium" />
            ) : (
              <SearchIcon fontSize="medium" />
            )}
          </button>
        )}
      </div>
    </a.div>
  );
};

SideBarContainer.Title = SidebarTitle;

export default SideBarContainer;
