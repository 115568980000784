const { ActionModalProvider } = require("context/ActionModalProvider");
const { AgrochemichalsProvider } = require("context/AgrochemichalsProvider");
const { CropStressesProvider } = require("context/CropStressesProvider");

const BulkActionWrapper = ({ children, cropId, isBio }) => {
  return (
    <CropStressesProvider cropId={cropId}>
      <AgrochemichalsProvider cropId={cropId}>
        <ActionModalProvider>{children}</ActionModalProvider>
      </AgrochemichalsProvider>
    </CropStressesProvider>
  );
};

export default BulkActionWrapper;
