import { Typography, styled } from "@mui/material";

const TypographyStyled = styled(Typography)(() => ({
  fontSize: "20px",
  fontWeight: 500,
  color: "#184c7b",
}));

const Title = ({ title, style = {}, className, children }) => (
  <TypographyStyled className={className} sx={style}>
    {typeof title !== "undefined" ? title : children}
  </TypographyStyled>
);

export default Title;
