import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { api } from "../api";
import { FETCH_STATE } from "../constants/fetchState";

const CropStressesContext = createContext({
  cropStresses: [],
  fetchState: {
    isLoading: false,
    error: undefined,
  },
});

const CropStressesProvider = ({ children, cropId }) => {
  const [cropStresses, setCropStresses] = useState([]);
  const [fetchState, setFetchState] = useState(FETCH_STATE.IDLE);

  const fetchCropStresses = useCallback(async () => {
    if (!cropId) {
      return;
    }

    setFetchState(FETCH_STATE.LOADING);

    try {
      let newCropStresses = [];
      const res = await api.crops.stresses(cropId);
      newCropStresses = res.data;
      if (!res.data || !res.data.length) {
        const newRes = await api.crops.stresses();
        newCropStresses = newRes.data;
      }
      setCropStresses(() => newCropStresses);
      setFetchState(FETCH_STATE.SUCCESS);
    } catch (error) {
      setFetchState(FETCH_STATE.ERROR);
    }
  }, [cropId]);

  useEffect(() => {
    fetchCropStresses();
  }, [fetchCropStresses]);

  const value = useMemo(
    () => ({
      cropStresses,
      fetchState,
    }),
    [cropStresses, fetchState]
  );

  return (
    <CropStressesContext.Provider value={value}>
      {children}
    </CropStressesContext.Provider>
  );
};

const useCropStressesContext = () => {
  const context = useContext(CropStressesContext);

  if (!context) {
    throw new Error(
      "useCropStressesContext must be used within a CropStressesProvider"
    );
  }
  return useContext(CropStressesContext);
};
export { CropStressesProvider, useCropStressesContext };
