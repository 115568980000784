import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const baseTextStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  fontSize: "12px",
  fontWeight: 300,
  color: "#000000",
};
const NoContentFound = ({ text, children, textStyle = {}, style = {} }) => {
  const { t } = useTranslation();

  const shouldDisplayChildren = Boolean(children);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        textAlign: "center",
        position: "relative",
        ...style,
      }}
    >
      {shouldDisplayChildren ? (
        <div style={{ ...baseTextStyle, textStyle }}>{children}</div>
      ) : (
        <Typography
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "12px",
            fontWeight: 300,
            color: "#000000",
            ...textStyle,
          }}
        >
          {text ? text : t("Components.NoContent")}
        </Typography>
      )}
    </div>
  );
};

export default NoContentFound;
