import { useCallback, useMemo } from "react";
import { useFormik } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useUserContext } from "context/UserProvider";
import { useActionChoicesContext } from "context/GeneralAgroProvider";
import { useActionModalGetContext } from "context/ActionModalProvider";
import { ACTION_STATUS } from "constants/actionStatus";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import { schemas } from "schemas";
import { useActionCalls, useTransformAction } from "./useActionsUtils";
import { constructActionPayload } from "./actionPayloadHandlers";

export const useActionFormikSetup = ({ selectedFields, onClose }) => {
  const { t } = useTranslation();
  const selectedFieldsIds = useMemo(
    () => selectedFields.map((field) => field.id),
    [selectedFields]
  );

  const { userData } = useUserContext();
  const { action, isBulk } = useActionModalGetContext();
  const { actionChoices, getActionById, getOperationById } =
    useActionChoicesContext();
  // const { stressTypes } = useStressTypesContext();
  const { transformAction } = useTransformAction();
  const { postAction, postBulkAction, patchAction, isLoading } = useActionCalls(
    selectedFieldsIds?.[0]
  );

  const isNew = action.type === ACTION_VIEW_MODE.new;

  const initialFormValues = useMemo(() => {
    return {
      ...(selectedFieldsIds.length === 1
        ? { fieldId: selectedFieldsIds[0] }
        : {
            fieldIds: selectedFieldsIds,
          }),
      ...(isNew
        ? {
            createdByUserId: userData.id,
            status: action.status,
            initialStatus: action.initialStatus,
            taskId: "",
          }
        : {}),
      ...(action.action
        ? { ...transformAction(action.action) }
        : {
            actionTypeId: 1,
            taskId: "",
            dateRange: {
              lower: "",
              upper: "",
            },
            // stressType: stressTypes?.[0]?.name[isEn ? "nameEn" : "nameGr"],
          }),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFieldsIds, action, transformAction]);

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: schemas.actions(t, actionChoices),
    validateOnChange: true,
    onSubmit: ({
      shouldCheckEnemyId,
      stressType,
      status,
      initialStatus,
      ...values
    }) => {
      const taskId = values.taskId;
      const type = getActionById(taskId);
      const extraHelpers = {
        otherOperation: getOperationById(values?.otherAction?.operationTypeId),
        diagnoseOperation: getOperationById(
          values?.diagnoseAction?.operationTypeId
        ),
      };
      const finalValues = constructActionPayload(type, values, extraHelpers);

      if (isBulk) {
        postBulkAction({
          status,
          values: finalValues,
          onSuccess: onClose,
          onError: onClose,
        });
        return;
      }
      if (isNew) {
        postAction({
          status,
          values: finalValues,
          onSuccess: onClose,
          onError: onClose,
        });
        return;
      }
      patchAction({
        status,
        initialStatus,
        values: finalValues,
        onSuccess: onClose,
        onError: onClose,
      });
    },
  });

  return { formik, isLoading };
};

export const useAnalysisFormikSetup = ({ selectedField, onClose, type }) => {
  const { t } = useTranslation();
  const { actionChoices, getActionById, getOperationById } =
    useActionChoicesContext();
  const { userData } = useUserContext();
  const { postAction, isLoading } = useActionCalls(selectedField?.id);

  const diagnoseTaskId = actionChoices.actions.diagnose.taskId;

  const initialTypeId =
    type === "leaf"
      ? actionChoices.actions.diagnose?.operations[2]?.operationTypeId
      : actionChoices.actions.diagnose?.operations[1]?.operationTypeId;

  const initialValues = {
    actionTypeId: 1,
    fieldId: selectedField?.id,
    taskId: diagnoseTaskId,
    createdByUserId: userData.id,
    datetimePerformed: moment().toISOString(),
    datetimePerformedEnd: moment().toISOString(),
    dateRange: { lower: "", upper: "" },
    timeTaken: 10,
    diagnoseAction: {
      analysis: {},
      sampleCoordinates: [],
      operationTypeId: initialTypeId,
    },
  };

  const handleSubmit = useCallback(
    (values, helpers) => {
      const taskId = values.taskId;
      const type = getActionById(taskId);
      const extraHelpers = {
        diagnoseOperation: getOperationById(
          values?.diagnoseAction?.operationTypeId
        ),
      };
      const finalValues = constructActionPayload(type, values, extraHelpers);

      postAction({
        status: ACTION_STATUS.performed,
        values: finalValues,
        onSuccess: () => {
          onClose?.();
          helpers.resetForm();
        },
      });
    },
    [getActionById, onClose, postAction, getOperationById]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: schemas.analysis(t),
    onSubmit: handleSubmit,
  });

  return { formik, isLoading };
};
