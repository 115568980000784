import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import AnalysisFields from "../../../analysis/AnalysisFields";
import Tabs from "../../../Tabs/Tabs";
import FieldAnalysisSamples from "../../../Maps/FieldAnalysisSamples";

const tabs = [
  {
    value: "analysis",
    label: "ActionModal.AnalysisResult.ValuesTab",
  },
  {
    value: "map",
    label: "ActionModal.AnalysisResult.SamplesMapTab",
  },
];

const AnalysisResults = ({ field, analysisFields, isViewMode }) => {
  const { t } = useTranslation();

  const [tab, setTab] = useState(tabs[0].value);
  const { values, setFieldValue } = useFormikContext();

  const { centerPoint, coordinates } = field;

  const center = useMemo(() => {
    if (!centerPoint) return undefined;
    return [centerPoint?.coordinates?.[1], centerPoint?.coordinates?.[0]];
  }, [centerPoint]);

  const latlngCoords = useMemo(
    () => coordinates?.coordinates?.[0]?.map((coord) => [coord[1], coord[0]]),
    [coordinates]
  );

  const samples = useMemo(
    () =>
      values?.diagnoseAction?.sampleCoordinates?.map((coord) => [
        coord?.latitude,
        coord?.longitude,
      ]),
    [values?.diagnoseAction]
  );

  const handleSamplesCoordinatesChange = (newSamples) => {
    setFieldValue(
      "diagnoseAction.sampleCoordinates",
      newSamples.map((coord) => ({
        latitude: coord[0],
        longitude: coord[1],
      }))
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        gap: "12px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Tabs
          tabs={tabs.map(({ value, label }) => ({
            value,
            label: t(label, {
              totalSamples: samples?.length || 0,
            }),
          }))}
          value={tab}
          setValue={setTab}
          variant="underline"
        />
      </div>
      <div style={{ flexGrow: 1, overflowY: "auto" }}>
        {tab === tabs[0].value && (
          <div
            style={{
              height: "100%",
              borderRadius: "8px",
              border: "1px solid #dcdee6",
            }}
          >
            <AnalysisFields
              analysisFields={analysisFields}
              isViewOnly={isViewMode}
            />
          </div>
        )}
        {tab === tabs[1].value && (
          <div
            style={{
              height: "400px",
              borderRadius: "8px",
              border: "1px solid #dcdee6",
              overflow: "hidden",
            }}
          >
            {Boolean(center) && (
              <FieldAnalysisSamples
                center={center}
                latlngCoords={latlngCoords}
                samples={samples}
                onSamplesChange={handleSamplesCoordinatesChange}
                isViewMode={isViewMode}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default AnalysisResults;
