import { InputAdornment, TextField, styled } from "@mui/material";
import { SmallText } from "components";

const CustomTextField = styled(TextField)(() => ({
  borderRadius: "1rem",
  "& .MuiFormLabel-root": {
    position: "inherit",
    marginBottom: "8px",
    marginLeft: "-8px",
    fontSize: "12px",
    fontWeight: 400,
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderRadius: "1rem",
  },
  ".MuiOutlinedInput-root": {
    fontSize: "14px",
  },
  ".MuiOutlinedInput-input": {
    padding: "5px 14px",
  },
  ".MuiInputBase-multiline": {
    padding: "8px 0px",
  },
  ".MuiInputAdornment-root": {
    marginLeft: 0,
  },
  ".MuiFormHelperText-root": {
    marginLeft: "4px",
    marginRight: "0px",
  },
  // border-color: #66afe9;
  // outline: 0;
  // -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
  // box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
}));

const viewModeStyle = {
  // ".MuiOutlinedInput-input": {
  //   paddingLeft: 0,
  //   paddingRight: 0,
  // },
  // ".MuiInputBase-multiline": {
  //   paddingLeft: 0,
  //   paddingRight: 0,
  // },
  "& .MuiFormLabel-root.Mui-disabled": {
    color: "rgba(0,0,0,.6)",
  },
  ".MuiOutlinedInput-root.Mui-disabled": {
    color: "unset",
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: 0,
  },
  ".MuiOutlinedInput-input.Mui-disabled": {
    "-webkit-text-fill-color": "unset",
    // WebkitTextFillColor
  },
};

const TextFieldCustom = ({
  className,
  id,
  defaultValue,
  endAdornment,
  label,
  helperText,
  isDisabled = false,
  isError = false,
  isMultiline = false,
  isFullWidth = true,
  isViewMode,
  name,
  onChange,
  placeholder,
  rows,
  minRows = 3,
  maxRows,
  style,
  value,
  labelStyle,
  inputRef,
  ...props
}) => {
  const inputId = (id || name) + (Math.random() * 1000).toString();

  const finalStyle = isViewMode ? { ...viewModeStyle, ...style } : style;

  return (
    <CustomTextField
      {...props}
      inputRef={inputRef}
      id={inputId}
      variant="outlined"
      disabled={isViewMode || isDisabled}
      error={Boolean(isError)}
      fullWidth={isFullWidth}
      multiline={isMultiline}
      rows={rows}
      maxRows={maxRows}
      minRows={minRows}
      placeholder={isViewMode ? "" : placeholder}
      name={name}
      onChange={onChange}
      value={value}
      label={label}
      defaultValue={defaultValue}
      helperText={helperText}
      sx={finalStyle}
      InputLabelProps={{
        shrink: false,
        style: labelStyle,
      }}
      size="small"
      className={className}
      InputProps={{
        ...props.InputProps,
        ...(endAdornment
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  {typeof endAdornment === "string" ? (
                    <SmallText>{endAdornment}</SmallText>
                  ) : (
                    endAdornment
                  )}
                </InputAdornment>
              ),
            }
          : {}),
      }}
    />
  );
};

export default TextFieldCustom;
