import { URLS } from "../utils/baseUrls";
import axiosInstance from "../utils/axiosInstance";

const getAllEquipments = () => axiosInstance.get(`${URLS.equipments}/`);

const getEquipment = (id) => axiosInstance.get(`${URLS.equipments}/${id}/`);
const getMachineryOptions = () =>
  axiosInstance.get(`${URLS.machineryInfrastructure}/machineries/`);

const addEquipment = (payload) =>
  axiosInstance.post(`${URLS.equipments}/`, payload);

const updateEquipment = (id, payload) =>
  axiosInstance.patch(`${URLS.equipments}/${id}/`, payload);

const deleteEquipment = (id) =>
  axiosInstance.delete(`${URLS.equipments}/${id}/`);

export const equipmentsApi = {
  fetchAll: getAllEquipments,
  fetchOne: getEquipment,
  fetchOptions: getMachineryOptions,
  add: addEquipment,
  update: updateEquipment,
  delete: deleteEquipment,
};
