import moment from "moment";
import { SYNC_FIELDS_STATUS, SYNC_FIELDS_STATUS_DICT } from "constants/opekepe";

export class OpekepeDto {
  static userSync(data) {
    const opekepeSyncData = {
      name: data?.userInfo?.name,
      vat: data?.userInfo?.tin,
      date: moment().toISOString(),
    };
    return opekepeSyncData;
  }

  static fieldsSync(data) {
    const syncedFieldData = {
      status: SYNC_FIELDS_STATUS_DICT[data?.syncStatus],
      totalFields: (data?.numSyncFields || 0) + (data?.numNewFields || 0),
      totalCrops: data?.numUniqueCrops || 0,
      dateSynced: data?.updatedAt
        ? moment(data?.updatedAt).toISOString()
        : moment(data?.createdAt).toISOString(),
    };
    return syncedFieldData;
  }

  static checkIfSynced(data, year) {
    const thisYearApplication = data?.find(
      (application) => String(application.year) === String(year)
    );
    let syncedStatus;
    if (!thisYearApplication) {
      syncedStatus = {
        isSynced: false,
      };
    } else {
      syncedStatus = {
        isSynced:
          SYNC_FIELDS_STATUS_DICT[thisYearApplication.syncStatus] ===
          SYNC_FIELDS_STATUS.SYNCED,
        totalFields:
          (thisYearApplication?.numSyncFields || 0) +
          (thisYearApplication?.numNewFields || 0),
        totalCrops: thisYearApplication?.numUniqueCrops,
        dateSynced: thisYearApplication?.updatedAt
          ? moment(thisYearApplication?.updatedAt).toISOString()
          : moment(thisYearApplication?.createdAt).toISOString(),
      };
    }

    return syncedStatus;
  }
}
