import clsx from "clsx";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import { NormalText } from "components";
import "./banner.css";

const Banner = ({ classNames = {}, children, type, text }) => {
  return (
    <div
      className={clsx(
        "banner-container",
        { "banner-success": type === "success" },
        { "banner-error": type === "error" },
        classNames.container
      )}
    >
      {type === "success" && <CheckIcon fontSize="medium" className="icon" />}
      {type === "error" && <ErrorIcon className="icon" />}
      {text ? <NormalText>{text}</NormalText> : children}
    </div>
  );
};

const BannerSuccess = ({ children, classNames, text }) => {
  return (
    <Banner type="success" classNames={classNames} text={text}>
      {children}
    </Banner>
  );
};
const BannerError = ({ children, classNames, text }) => {
  return (
    <Banner type="error" classNames={classNames} text={text}>
      {children}
    </Banner>
  );
};

Banner.Success = BannerSuccess;
Banner.Error = BannerError;

export default Banner;
