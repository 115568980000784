import { Typography, styled } from "@mui/material";

const TypographyStyled = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: 500,
}));

const SubTitle = ({ text, style = {}, className, children }) => (
  <TypographyStyled className={className} sx={style}>
    {typeof text !== "undefined" ? text : children}
  </TypographyStyled>
);

export default SubTitle;
