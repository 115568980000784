import { useEffect } from "react";
import { FormikProvider } from "formik";
import "../action-modal.css";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import { useActionChoicesContext } from "context/GeneralAgroProvider";
import {
  useActionModalGetContext,
  useActionModalSetContext,
} from "context/ActionModalProvider";
import ActionCause from "../cause/ActionCause";
import ActionBaseInfo from "../base-info/ActionBaseInfo";
import { useActionFormikSetup } from "../utils/useFormikSetup";
import ActionModalHeader from "../header/action-header/ActionModalHeader";
import ActionDetails from "../details/ActionDetails";
import ActionCommentsSection from "../comments/ActionCommentsSection";

const ActionReadyContent = ({ selectedFields, onClose }) => {
  const { actionChoices } = useActionChoicesContext();
  const { action, isBulk } = useActionModalGetContext();
  const { setAction } = useActionModalSetContext();

  const { formik, isLoading: isCallLoading } = useActionFormikSetup({
    selectedFields,
    onClose,
  });

  useEffect(() => {
    if (typeof setAction === "function") {
      setAction((prev) => ({
        ...prev,
        status: formik.values?.status,
      }));
    }
  }, [formik.values?.status, setAction]);

  const otherActionsTaskId = actionChoices.actions.other?.taskId;
  const shouldShowActionDetails =
    formik.values?.taskId &&
    (formik.values.taskId !== otherActionsTaskId ||
      (formik.values.taskId === otherActionsTaskId &&
        formik.values.otherAction?.operationTypeId));

  const shouldShowComments = action.type !== ACTION_VIEW_MODE.new;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className="action-modal-form">
        <ActionModalHeader
          fieldId={!isBulk ? selectedFields[0].id : undefined}
          onClose={onClose}
          isLoading={isCallLoading}
        />
        <div className="action-modal-form-inner">
          <div className="action-modal-card">
            <div className="action-modal-top-box">
              <ActionCause
                viewMode={action.type}
                actionStatus={action.status}
              />
            </div>
            <div className="action-modal-top-box">
              <ActionBaseInfo
                viewMode={action.type}
                actionStatus={action.status}
                selectedField={!isBulk ? selectedFields[0] : undefined}
              />
            </div>
          </div>
          {shouldShowActionDetails && (
            <div className="action-details-wrapper">
              <ActionDetails
                viewMode={action.type}
                actionStatus={action.status}
              />
            </div>
          )}
          {shouldShowComments && (
            <div className="action-modal-card">
              <ActionCommentsSection />
            </div>
          )}
        </div>
      </form>
    </FormikProvider>
  );
};

export default ActionReadyContent;
