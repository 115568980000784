import { Warning } from "@mui/icons-material";
import { NormalText } from "components";

const BulkActionWarning = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        paddingLeft: "0.5rem",
      }}
    >
      <Warning fontSize="small" style={{ marginTop: "0.25rem" }} />
      <NormalText>{children}</NormalText>
    </div>
  );
};
export default BulkActionWarning;
