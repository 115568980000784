import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
} from "react";
import { ACTION_STATUS } from "../constants/actionStatus";

const DISPATCH_TYPES = {
  init: "init",
  set: "set",
  edit: "edit",
  transfer: "transfer",
  delete: "delete",
};

const ActionsContext = createContext({
  selectedAction: {},
  setSelectedAction: (action) => {},
  getPerformedActions: () => {},
  getSuggestedActions: () => {},
  actions: [],
  dispatchActions: ({
    type,
    actionType,
    action,
    actions,
    transferTo = {
      type: null,
      action: null,
    },
  }) => {},
});

const reducer = (
  state,
  { type, action, actions, transferTo = { type: null, action: null } }
) => {
  if (transferTo && transferTo.type) {
    if (transferTo.action == null) {
      throw Error(
        "transferTo.type should come with a transferTo.action of the newly transfered action"
      );
    }
    if (
      ![ACTION_STATUS.performed, ACTION_STATUS.suggested].includes(
        transferTo.type
      )
    ) {
      throw Error(
        `transferTo.type should be one of ['${ACTION_STATUS.performed}', '${ACTION_STATUS.suggested}']`
      );
    }
  }

  switch (type) {
    case DISPATCH_TYPES.init:
      return actions;
    case DISPATCH_TYPES.set:
      if (action) {
        return [...state, action];
      }
      if (actions) {
        return [...state, ...actions];
      }
      break;
    case DISPATCH_TYPES.edit:
      return state.map((currAction) => {
        if (currAction.id === action.id) {
          return action;
        }
        return currAction;
      });
    case DISPATCH_TYPES.transfer:
      return state?.map((currAction) => {
        if (currAction.id === action.id) {
          return { ...transferTo.action, status: transferTo.type };
        }
        return currAction;
      });
    case DISPATCH_TYPES.delete:
      return state.filter((currAction) => currAction.id !== action.id);
    default:
      break;
  }
};

const reducerOld = (
  state,
  {
    type,
    actionType,
    action,
    actions,
    transferTo = { type: null, action: null },
  }
) => {
  if (!actionType) {
    throw Error("actionType is required");
  }
  if (
    ![ACTION_STATUS.performed, ACTION_STATUS.suggested].includes(actionType)
  ) {
    throw Error(
      `actionType should be one of ['${ACTION_STATUS.performed}', '${ACTION_STATUS.suggested}']`
    );
  }
  if (transferTo && transferTo.type) {
    if (transferTo.action == null) {
      throw Error(
        "transferTo.type should come with a transferTo.action of the newly transfered action"
      );
    }
    if (
      ![ACTION_STATUS.performed, ACTION_STATUS.suggested].includes(
        transferTo.type
      )
    ) {
      throw Error(
        `transferTo.type should be one of ['${ACTION_STATUS.performed}', '${ACTION_STATUS.suggested}']`
      );
    }
  }
  switch (type) {
    case DISPATCH_TYPES.set:
      if (action) {
        return { ...state, [actionType]: [...state[actionType], action] };
      }
      if (actions) {
        return { ...state, [actionType]: actions };
      }
      break;
    case DISPATCH_TYPES.edit:
      if (Array.isArray(state[actionType])) {
        const newState = {
          ...state,
          [actionType]: state[actionType].map((currAction) => {
            if (currAction.id === action.id) {
              return action;
            }
            return currAction;
          }),
        };
        return newState;
      }
      break;
    case DISPATCH_TYPES.transfer:
      let newState = {
        ...state,
        [transferTo.type]: [...state[transferTo.type], transferTo.action],
      };
      if (Array.isArray(state[actionType])) {
        newState = {
          ...newState,
          [actionType]: state[actionType].filter(
            (currAction) => currAction.id !== action.id
          ),
        };
        return newState;
      }
      break;
    case DISPATCH_TYPES.delete:
      if (Array.isArray(state[actionType])) {
        const newState = {
          ...state,
          [actionType]: state[actionType].filter(
            (currAction) => currAction.id !== action.id
          ),
        };
        return newState;
      }
      break;
    default:
      break;
  }
  throw Error("Unknown action" + type);
};

const ActionsProvider = ({ children }) => {
  const [selectedAction, setSelectedAction] = useState();
  const [actions, dispatch] = useReducer(reducer, []);

  const getPerformedActions = useCallback(
    () => actions.filter((action) => action.status === ACTION_STATUS.performed),
    [actions]
  );

  const getSuggestedActions = useCallback(
    () => actions.filter((action) => action.status === ACTION_STATUS.suggested),
    [actions]
  );

  const values = useMemo(
    () => ({
      selectedAction,
      setSelectedAction,
      actions,
      dispatchActions: dispatch,
      getPerformedActions,
      getSuggestedActions,
    }),
    [
      selectedAction,
      setSelectedAction,
      actions,
      dispatch,
      getPerformedActions,
      getSuggestedActions,
    ]
  );

  return (
    <ActionsContext.Provider value={values}>{children}</ActionsContext.Provider>
  );
};

const useActionsContext = () => {
  const context = useContext(ActionsContext);

  // Commented that out, cause there is a case where the context is used in a hook not always under this provider
  // if (!context) {
  //   throw new Error("useActionsContext must be used within a ActionsProvider");
  // }

  return context;
};

export { ActionsProvider, useActionsContext, DISPATCH_TYPES };
