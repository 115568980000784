import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { SmallText, Title } from "components";
import { FormNumberInput } from "form";
import { ACTION_STATUS } from "constants/actionStatus";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import { OPERATIONS } from "constants/actions";
import { useActionChoicesContext } from "context/GeneralAgroProvider";
import DateSpan from "../date-span/DateSpan";
import { EquipmentUsed } from "../equipment";
import ActionModalDescriptionSection from "../description/ActionModalDescriptionSection";
import DetailSection from "../DetailSection";

const OtherActionPerformed = ({ viewMode, isFullWidth }) => {
  const { t } = useTranslation();
  const status = ACTION_STATUS.performed;
  const isViewMode = viewMode === ACTION_VIEW_MODE.view;

  const { values } = useFormikContext();
  const { isSpecificOperation } = useActionChoicesContext();

  const isGatheringOperation = useMemo(() => {
    return isSpecificOperation?.(
      OPERATIONS.OTHER.GATHERING,
      values?.otherAction?.operationTypeId
    );
  }, [values?.otherAction?.operationTypeId, isSpecificOperation]);

  return (
    <div className="action-modal-card action-details-box ">
      <div className={isFullWidth && "details-half-width"}>
        <Title title={t("ActionModal.Details.Performed")} />
        <DateSpan viewMode={viewMode} status={status} />
        <EquipmentUsed isInEditMode={viewMode !== ACTION_VIEW_MODE.view} />
        {isGatheringOperation && (
          <DetailSection
            title={t("ActionModal.Output.Title")}
            isRequired={!isViewMode}
          >
            <FormNumberInput
              style={{ width: "50%" }}
              name="otherAction.quantity"
              endAdornment={t(`OperationUnits.kilogram`)}
            />
            <SmallText className="field-description">
              {t("ActionModal.Output.Description")}
            </SmallText>
          </DetailSection>
        )}
        <ActionModalDescriptionSection
          actionBaseName="otherAction"
          isViewMode={isViewMode}
        />
      </div>
    </div>
  );
};

export default OtherActionPerformed;
