const TERMS_CONDITIONS_LINK = "https://www.camvio.gr/terms-of-use";
const PRIVACY_POLICY_LINK = "https://www.camvio.gr/privacy-policy";
const OPEKEPE_LOGIN_LINK = (isDev) => {
  return isDev
    ? "https://accounts.opekepe.gr/realms/eco-schemes/protocol/openid-connect/auth?response_type=code&client_id=camvio&redirect_uri=https%3A%2F%2Fdev.camvio.gr%2Fauth%2Fopekepe%2Fcallback%2F"
    : "https://accounts.opekepe.gr/realms/eco-schemes/protocol/openid-connect/auth?response_type=code&client_id=camvio&redirect_uri=https%3A%2F%2Fapp.camvio.gr%2Fauth%2Fopekepe%2Fcallback%2F";
};
export const LINKS = {
  TERMS_CONDITIONS: TERMS_CONDITIONS_LINK,
  PRIVACY_POLICY: PRIVACY_POLICY_LINK,
  OPEKEPE_LOGIN: OPEKEPE_LOGIN_LINK,
};
