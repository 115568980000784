import { LINKS } from "../constants/camvioLinks";

export const paths = {
  addEquipment: "/equipments/new",
  addField: "/fields/new",
  agronomist: (id) => `/agronomists/${id}`,
  agronomists: "/agronomists",
  dashboardKyd: "/dahboard",
  editEquipment: (id) => `/equipments/${id}/edit`,
  equipment: (id) => `/equipments/${id}`,
  equipments: "/equipments",
  farmer: (id) => `/farmers/${id}`,
  farmers: "/farmers",
  field: (id) => `/fields/${id}`,
  fields: "/fields",
  forgotPassword: "/auth/forgot-password",
  home: "/",
  icognitoOpekepe: "/auth/go-back-to-camvio",
  login: "/auth/login",
  passwordRecovery: "/password-recovery/:uid/:token",
  privacyPolicy: LINKS.PRIVACY_POLICY,
  osdeSync: "/osde-sync",
  opekepeLogin: (isDev) => LINKS.OPEKEPE_LOGIN(isDev),
  register: "/auth/register",
  settings: {
    account: "/settings/account",
    index: "/settings",
    machineries: "/settings/machineries",
    notifications: "/settings/notifications",
    profile: "/settings/profile",
  },
  terms: LINKS.TERMS_CONDITIONS,
  twoFactorAuthentication: "/two-factor-authentication",
  unauthorized: "/unauthorized-error403",
  userActivation: "/users/activate/:uid/:token",
};
