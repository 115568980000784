import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { api } from "api";
import { FETCH_STATE } from "constants/fetchState";
import { storage } from "utils/localStorage";
import { paths } from "utils/paths";

const useOpekepeSyncSteps = ({ user, scope, onSyncSuccess }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentPath = useMemo(
    () => location.pathname + location.search,
    [location.pathname, location.search]
  );

  const [error, setError] = useState();
  const [fetchState, setFetchState] = useState(FETCH_STATE.IDLE);
  const [steps, setSteps] = useState([
    { state: FETCH_STATE.IDLE, data: undefined },
    { state: FETCH_STATE.IDLE, data: undefined },
  ]);

  const opekepeLink = useMemo(() => {
    const isDev =
      process.env.NODE_ENV === "development" || process.env.REACT_APP_ENV_QA;
    return paths.opekepeLogin(isDev);
  }, []);

  const buttonText = useMemo(() => {
    if (steps[1].state === FETCH_STATE.SUCCESS) {
      return t("OpekepeSync.SubmitBtn.ShowFields");
    }
    if (steps[1].state === FETCH_STATE.ERROR) {
      return t("OpekepeSync.SubmitBtn.TryAgain");
    }
    if (steps[0].state === FETCH_STATE.SUCCESS) {
      return t("OpekepeSync.SubmitBtn.SyncFields");
    }
    if (steps[0].state === FETCH_STATE.ERROR) {
      return t("OpekepeSync.SubmitBtn.TryAgain");
    }
    return t("OpekepeSync.SubmitBtn.SyncAccount");
  }, [steps, t]);

  const handleSubmit = useCallback(() => {
    // Initiate OPEKEPE login
    if (
      steps[0].state === FETCH_STATE.IDLE ||
      steps[0].state === FETCH_STATE.ERROR
    ) {
      storage.saveOpekepeRedirectUrl(currentPath);
      storage.saveSyncedUser(user);

      // window.open(paths.opekepeLogin(isDev), null, "popup");
      window.location.href = opekepeLink;
      return;
    }
    // Initiate fields sync
    if (
      steps[1].state === FETCH_STATE.IDLE ||
      steps[1].state === FETCH_STATE.ERROR
    ) {
      const user = storage.readSyncedUser();
      if (!user?.id) return;

      setError();
      setFetchState(FETCH_STATE.LOADING);
      api.opekepe
        .syncFields({ userId: user.id, scope })
        .then(({ data, error }) => {
          if (error) {
            setSteps((prev) => [
              prev[0],
              {
                state: FETCH_STATE.ERROR,
              },
            ]);
            setFetchState(FETCH_STATE.ERROR);
            if (error.response?.status === 404) {
              setError(t("OpekepeSync.Fields.ErrorNotFound"));
            } else {
              setError(t("OpekepeSync.Fields.Error"));
            }
            return;
          }
          setSteps((prev) => [
            prev[0],
            {
              state: FETCH_STATE.SUCCESS,
              data: {
                fields: data.totalFields,
                crops: data.totalCrops,
                dateSynced: data.dateSynced,
              },
            },
          ]);
          setFetchState(FETCH_STATE.SUCCESS);
          onSyncSuccess?.();

          // remove synced user from local storage
          storage.getSyncedUser();
        });
      return;
    }

    // TODO_LATER: - Navigate to view fields but where?
  }, [currentPath, scope, steps, t, user, onSyncSuccess, opekepeLink]);

  /**
   * This runs on mount.
   * When loading the consumer component, we probably are coming back from the external OPEKEPE Login Page.
   * To track that we use the local storage to check if we have a code.
   */
  useEffect(() => {
    setError();
    if (!scope) return;

    // TODO: - if we have already synced the account, do nothing but update the steps

    // 2. if we have not synced the account, check if we have a code in storage from a successful login
    const code = storage.getOpekepeLoginCode();
    if (!code) return;

    // 3. make an API call to sync account
    setFetchState(FETCH_STATE.LOADING);
    api.opekepe.syncAccount({ code, scope }).then(({ data, error }) => {
      if (error) {
        setSteps((prev) => [
          {
            state: FETCH_STATE.ERROR,
          },
          prev[1],
        ]);
        setError(t("OpekepeSync.Account.Error"));
        setFetchState(FETCH_STATE.ERROR);
        return;
      }
      // if the call is successful, update the steps data with res.data
      setSteps((prev) => [
        {
          state: FETCH_STATE.SUCCESS,
          data: {
            name: data.name,
            vat: data.vat,
            date: data.date,
          },
        },
        prev[1],
      ]);
      setFetchState(FETCH_STATE.SUCCESS);
    });
  }, [scope, t]);

  const handleIcognitoSync = useCallback(
    ({ data, error }) => {
      if (error) {
        setSteps((prev) => [
          {
            state: FETCH_STATE.ERROR,
          },
          prev[1],
        ]);
        setError(t("OpekepeSync.Account.Error"));
        setFetchState(FETCH_STATE.ERROR);
        return;
      }
      setSteps((prev) => [
        {
          state: FETCH_STATE.SUCCESS,
          data: {
            name: data?.name,
            vat: data?.vat,
            date: data?.date,
          },
        },
        prev[1],
      ]);
      setFetchState(FETCH_STATE.SUCCESS);
    },
    [t]
  );

  return {
    steps,
    buttonText,
    fetchState,
    error,
    opekepeLink,
    onSubmit: handleSubmit,
    onIcognitoSynced: handleIcognitoSync,
  };
};

export default useOpekepeSyncSteps;
