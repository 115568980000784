import { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { NoContentFound, SelectorNew, DurationInput } from "components";
import { useActionChoicesContext } from "context/GeneralAgroProvider";
import { useSelectedFieldContext } from "context/SelectedFieldProvider";
import { ACTION } from "constants/actions";
// import { EQUIPMENT_TYPES } from "constants/equipmentTypes";
import EquipmentItem from "./EquipmentItem";

const EquipmentItemMemo = memo(EquipmentItem);

const EquipmentUsed = ({ isInEditMode }) => {
  const { t, i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");

  const { equipments } = useSelectedFieldContext();
  const { getActionById } = useActionChoicesContext();

  const { values, setFieldValue } = useFormikContext();
  const shouldShowDurationInput = Boolean(values?.equipment);

  let actionBaseName;
  switch (getActionById(values?.taskId)) {
    case ACTION.watering:
      actionBaseName = "waterAction";
      break;
    case ACTION.diagnose:
      actionBaseName = "diagnoseAction";
      break;
    case ACTION.fertilize:
      actionBaseName = "fertilizeAction";
      break;
    case ACTION.other:
      actionBaseName = "otherAction";
      break;
    default:
      break;
  }

  const equipmentsForThisAction = useMemo(() => {
    const list = [values?.taskId, values?.[actionBaseName]?.operationTypeId];

    return equipments
      .filter(({ machineryRead }) =>
        machineryRead?.operations?.some((operation) => list.includes(operation))
      )
      .map((equipment) => {
        const { id, machineryRead } = equipment;
        return {
          ...equipment,
          value: id,
          label: isEn ? machineryRead.nameEn : machineryRead.nameGr,
        };
      });
  }, [equipments, values, actionBaseName, isEn]);

  // const equipmentCategories = [
  //   {
  //     value: EQUIPMENT_TYPES.MACHINE,
  //     title: t("Equipment.Machines"),
  //   },
  //   {
  //     value: EQUIPMENT_TYPES.TOOL,
  //     title: t("Equipment.Tools"),
  //   },
  // ];

  // const equipmentOptions = equipmentsForThisAction.map((equipment) => ({
  //   value: equipment.id,
  //   label: equipment.machineryRead[isEn ? "nameEn" : "nameGr"],
  //   group: equipment.machineryRead.machineryType,
  // }));

  const handleEquipmentChange = useCallback(
    (e) => {
      setFieldValue("equipment", [
        {
          duration: 0,
          id: e.target.value,
        },
      ]);
    },
    [setFieldValue]
  );

  const handleDurationChange = useCallback(
    (duration) => {
      const currentEquipment = values?.equipment?.[0];

      if (currentEquipment) {
        setFieldValue("equipment", [
          {
            ...currentEquipment,
            duration: duration,
          },
        ]);
      }
    },
    [setFieldValue, values?.equipment]
  );

  return equipmentsForThisAction?.length > 0 ? (
    <>
      <SelectorNew
        value={values?.equipment?.[0]?.id}
        onChange={handleEquipmentChange}
        isDisabled={!isInEditMode}
        isViewMode={!isInEditMode}
        // groups={equipmentCategories}
        options={equipmentsForThisAction}
        itemRenderer={({ option }) => <EquipmentItemMemo equipment={option} />}
        // renderValue={(value) => }
      />
      {shouldShowDurationInput && (
        <DurationInput
          placeholder={t("ActionModal.Duration.Title") + "*"}
          style={{ marginTop: "8px", width: "150px" }}
          value={values?.equipment?.[0]?.duration}
          onChange={handleDurationChange}
          isViewMode={!isInEditMode}
          isDisabled={!isInEditMode}
          units={t("ActionModal.Duration.InMinutes")}
        />
      )}
    </>
  ) : (
    <NoContentFound
      text={t("ActionModal.Equipment.NotFound")}
      style={{ height: "60px" }}
      textStyle={{ width: "80%" }}
    />
  );
};

export default EquipmentUsed;
