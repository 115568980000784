const filterUserOnTerm = (user = {}, term = "") => {
  if (!term) return true;
  const termLowerCase = term.toLowerCase();

  if (user.firstName?.toLowerCase().includes(termLowerCase)) {
    return true;
  }

  if (user.lastName?.toLowerCase().includes(termLowerCase)) {
    return true;
  }

  if (user.email?.toLowerCase().includes(termLowerCase)) {
    return true;
  }

  if (user.phone?.toLowerCase().includes(termLowerCase)) {
    return true;
  }
  if (user.taxNumber?.toLowerCase().includes(termLowerCase)) {
    return true;
  }

  return false;
};

const filterVarietyOnTerm = (varietyRead, term) => {
  if (!term) return true;
  const termLowerCase = term.toLowerCase();

  if (varietyRead.cropNameGr?.toLowerCase().includes(termLowerCase)) {
    return true;
  }

  if (varietyRead.cropNameEn?.toLowerCase().includes(termLowerCase)) {
    return true;
  }

  if (varietyRead.varietyNameGr?.toLowerCase().includes(termLowerCase)) {
    return true;
  }

  if (varietyRead.varietyNameEn?.toLowerCase().includes(termLowerCase)) {
    return true;
  }

  if (varietyRead.taxonomyNameGr?.toLowerCase().includes(termLowerCase)) {
    return true;
  }

  if (varietyRead.taxonomyNameEn?.toLowerCase().includes(termLowerCase)) {
    return true;
  }

  return false;
};

const filterAreaOnTerm = (area, term = "") => {
  if (!term) return true;
  const termLowerCase = term.toLowerCase();
  if (area.regionNameGr?.toLowerCase().includes(termLowerCase)) {
    return true;
  }
  if (area.regionNameEn?.toLowerCase().includes(termLowerCase)) {
    return true;
  }
  if (area.prefectureNameGr?.toLowerCase().includes(termLowerCase)) {
    return true;
  }
  if (area.prefectureNameEn?.toLowerCase().includes(termLowerCase)) {
    return true;
  }
  if (area.nameGr?.toLowerCase().includes(termLowerCase)) {
    return true;
  }
  if (area.nameEn?.toLowerCase().includes(termLowerCase)) {
    return true;
  }
  return false;
};

const filterFieldOnTerm = (field, term = "") => {
  if (!term) return true;

  const termLowerCase = term.toLowerCase();
  if (field.name?.toLowerCase().includes(termLowerCase)) {
    return true;
  }

  let userFound = false;
  field.agronomistDetails?.forEach((agronomist) => {
    if (filterUserOnTerm(agronomist, termLowerCase)) {
      userFound = true;
      return;
    }
  });
  if (userFound) {
    return true;
  }

  field.farmerDetails?.forEach((farmer) => {
    if (filterUserOnTerm(farmer, termLowerCase)) {
      userFound = true;
      return;
    }
  });
  if (userFound) {
    return true;
  }

  if (filterVarietyOnTerm(field.varietyRead, termLowerCase)) {
    return true;
  }

  if (filterAreaOnTerm(field.municipality, termLowerCase)) {
    return true;
  }

  return false;
};

const filterEquipmentByTerm = (equipment, term = "") => {
  if (!term) return true;

  const termLowerCase = term.toLowerCase();
  if (equipment.alias?.toLowerCase().includes(termLowerCase)) {
    return true;
  }

  const details = equipment.machineryRead;
  if (details.nameGr?.toLowerCase().includes(termLowerCase)) {
    return true;
  }
  if (details.nameEn?.toLowerCase().includes(termLowerCase)) {
    return true;
  }

  return false;
};

const filterByTerm = {
  area: filterAreaOnTerm,
  crop: filterVarietyOnTerm,
  equipment: filterEquipmentByTerm,
  field: filterFieldOnTerm,
  user: filterUserOnTerm,
};
export default filterByTerm;
