import {
  ListItemButton as MuiListItemButton,
  ListItemText as MuiListItemText,
} from "@mui/material";

const ListItem = ({
  primaryText,
  secondaryText,
  bottomHelperText,
  isDisabled,
  isSelected,
  topHelperText,
  midHelperText,
  primaryTextStyle = {},
  topHelperTextStyle = {},
  secondaryTextStyle = {},
  bottomHelperTextStyle = {},
  midHelperTextStyle = {},
  style = {},
  onClick,
  onCheck,
}) => {
  return (
    <MuiListItemButton
      onClick={onClick}
      disabled={isDisabled}
      sx={{
        borderBottom: "1px solid #E1E1E1",
        padding: "0px 8px",
        backgroundColor: isSelected ? "#EBEBEB" : "#ffffff",
        cursor: "pointer",
        ":hover": {
          backgroundColor: "#f7f7fc",
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        width: "100%",
        ...style,
      }}
    >
      <div
        style={{
          width: "100%",
          margin: "6px 0px",
          display: "flex",
          gap: "8px",
          overflowX: "hidden",
        }}
      >
        {onCheck && (
          <input
            type="checkbox"
            onClick={(e) => e.stopPropagation()}
            onChange={onCheck}
            style={{ flexShrink: 0, cursor: "pointer" }}
          />
        )}
        <div style={{ width: onCheck ? "calc(100% - 15px)" : "100%" }}>
          <MuiListItemText
            sx={{ margin: 0 }}
            primaryTypographyProps={{
              sx: {
                fontWeight: 500,
                fontSize: "14px",
                color: "#184C7B",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                ...primaryTextStyle,
              },
            }}
            secondaryTypographyProps={{
              sx: {
                fontWeight: 300,
                fontSize: "14px",
                color: "#6969A4",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                ...secondaryTextStyle,
              },
            }}
            primary={
              topHelperText ? (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "#184C7B",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      ...primaryTextStyle,
                    }}
                  >
                    {primaryText}
                  </span>
                  <span
                    style={{
                      flexShrink: 0,
                      fontWeight: 300,
                      fontSize: "12px",
                      color: "#6969A4",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      ...topHelperTextStyle,
                    }}
                  >
                    {topHelperText}
                  </span>
                </span>
              ) : (
                primaryText
              )
            }
            secondary={
              midHelperText ? (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#6969A4",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      ...secondaryTextStyle,
                    }}
                  >
                    {secondaryText}
                  </span>
                  <span
                    style={{
                      flexShrink: 0,
                      fontWeight: 300,
                      fontSize: "12px",
                      color: "#6969A4",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "40%",
                      ...midHelperTextStyle,
                    }}
                  >
                    {midHelperText}
                  </span>
                </span>
              ) : (
                secondaryText
              )
            }
          />
          <MuiListItemText
            sx={{ margin: 0 }}
            primaryTypographyProps={{
              sx: {
                fontWeight: 400,
                fontSize: "12px",
                color: "#184C7B",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                ...bottomHelperTextStyle,
              },
            }}
            primary={bottomHelperText}
          />
        </div>
      </div>
    </MuiListItemButton>
  );
};

export default ListItem;
