import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { ACTION_STATUS } from "constants/actionStatus";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import { FormRadioGroupInput, FormSelectInput } from "form";
import Title from "../../../Typography/Title";
import SmallText from "../../../Typography/SmallText";
import DateSpan from "../date-span/DateSpan";
import QuantityInput from "../quantity/QuantityInput";
import DetailSection from "../DetailSection";
import {
  useDrugFormEffects,
  useFilteredDrugSubstances,
  useSubstanceCompositionOptions,
  useSuggestedDrugQuantity,
} from "./useDrugFormUtils";
import { EquipmentUsed } from "../equipment";
import ActionModalDescriptionSection from "../description/ActionModalDescriptionSection";
import useAvailableQuantityUnits from "components/action-modal/utils/useAvailableOperationUnits";

const DrugPerformed = ({ viewMode, isFullWidth }) => {
  const { t, i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");

  const { values } = useFormikContext();
  const status = ACTION_STATUS.performed;
  const isViewMode = viewMode === ACTION_VIEW_MODE.view;

  const { filteredSubstances, bioSelections } = useFilteredDrugSubstances();
  const { suggestedQuantity } = useSuggestedDrugQuantity(filteredSubstances);
  const { selectedPesticideId } = useDrugFormEffects();
  const { availableQuantityUnits } = useAvailableQuantityUnits(
    values.taskId,
    values.drugAction?.operationTypeId
  );

  const {
    activeSubstanceOptions,
    activeSubstancesGroups,
    compositionOptions,
    brandNameOptions,
    handleCompositionChange,
  } = useSubstanceCompositionOptions(filteredSubstances, selectedPesticideId);

  return (
    <div className="action-modal-card action-details-box ">
      <div className={isFullWidth && "details-half-width"}>
        <Title title={t("ActionModal.Details.Performed")} />
        <DateSpan viewMode={viewMode} status={status} />

        <DetailSection
          title={t("ActionModal.ActiveSubstance")}
          isRequired={!isViewMode}
        >
          <div className={!isViewMode && "action-details-column-multiple"}>
            <FormRadioGroupInput
              name="drugAction.bio"
              isViewMode={isViewMode}
              shouldHaveErrorBehaviorAfterSubmit
              options={bioSelections.map(
                ({ value, label: { nameEn, nameGr } }) => ({
                  value,
                  label: isEn ? nameEn : nameGr,
                })
              )}
            />
            <FormSelectInput
              name="drugAction.pesticideId"
              label={isViewMode ? t("ActionModal.ActiveSubstance") : undefined}
              placeholder={t("ActionModal.ActiveSubstance")}
              isDisabled={values?.drugAction?.bio == null}
              isViewMode={isViewMode}
              shouldHaveErrorBehaviorAfterSubmit
              groups={activeSubstancesGroups}
              options={activeSubstanceOptions}
            />
            <FormSelectInput
              value={JSON.stringify({
                composition: values?.drugAction?.composition + "",
                compositionUnits: values?.drugAction?.compositionUnits,
              })}
              name="drugAction.composition"
              label={isViewMode ? t("ActionModal.Composition") : undefined}
              placeholder={t("ActionModal.Composition")}
              isDisabled={!values?.drugAction?.pesticideId}
              shouldHaveErrorBehaviorAfterSubmit
              isViewMode={isViewMode}
              onChange={handleCompositionChange}
              options={compositionOptions}
            />
            <FormSelectInput
              name="drugAction.brandName"
              label={isViewMode ? t("ActionModal.BrandName") : undefined}
              placeholder={t("ActionModal.BrandName")}
              isDisabled={!values?.drugAction?.composition}
              shouldHaveErrorBehaviorAfterSubmit
              isViewMode={isViewMode}
              options={brandNameOptions}
            />
          </div>
        </DetailSection>
        <DetailSection
          title={t("ActionModal.Quantity")}
          isRequired={!isViewMode}
        >
          <QuantityInput
            isViewMode={isViewMode}
            actionBaseName="drugAction"
            quantityOptions={availableQuantityUnits}
          />
          {!isViewMode && suggestedQuantity && (
            <SmallText
              text={t("ActionModal.SuggestedQuantity", {
                quantity: suggestedQuantity,
              })}
              style={{
                color: "#818181",
                paddingLeft: "8px",
                marginTop: "4px",
                fontStyle: "italic",
              }}
            />
          )}
        </DetailSection>
        <EquipmentUsed isInEditMode={viewMode !== ACTION_VIEW_MODE.view} />
        <ActionModalDescriptionSection
          actionBaseName="drugAction"
          isViewMode={isViewMode}
        />
      </div>
    </div>
  );
};
export default DrugPerformed;
