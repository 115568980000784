import { useMemo } from "react";
import PropTypes from "prop-types";
import { FixedSizeList as List } from "react-window";
import { useTreeHelpers } from "hooks/useTreeHelpers";
import TreeNode, { treeNodePropType } from "./TreeNode";
import "./tree.css";

const flattenTree = (nodes) => {
  return nodes.reduce((acc, node) => {
    acc.push({ ...node });
    if (node.children && node.isExpanded) {
      acc = acc.concat(flattenTree(node.children));
    }
    return acc;
  }, []);
};

const Tree = ({
  data,
  height,
  itemSize = 30,
  onChange,
  checkedNodes = [],
  className = {},
  nodeStyle = {},
  isDisabled,
}) => {
  const { nodes, handleCheck, handleToggle } = useTreeHelpers({
    data,
    checkedNodes,
    onChange,
  });

  // Memoize flattened tree structure
  const flattenedNodes = useMemo(() => flattenTree(nodes), [nodes]);

  const finalHeight = useMemo(() => {
    if (height) {
      return Math.min(flattenedNodes.length * itemSize, height);
    }
    return flattenedNodes.length * itemSize;
  }, [flattenedNodes.length, height, itemSize]);

  return (
    <List
      height={finalHeight}
      itemSize={itemSize}
      itemCount={flattenedNodes.length}
      className="tree"
    >
      {({ index, style }) => {
        const node = flattenedNodes[index];
        return (
          <div style={style}>
            <TreeNode
              isDisabled={isDisabled}
              node={node}
              onToggle={handleToggle}
              onCheck={handleCheck}
              checkedNodes={checkedNodes.map((node) => node.value)}
              style={nodeStyle}
              className={className}
            />
          </div>
        );
      }}
    </List>
  );
};

export default Tree;

Tree.propTypes = {
  checkedNodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(treeNodePropType).isRequired,
  height: PropTypes.number,
  itemSize: PropTypes.number,
  onChange: PropTypes.func,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  style: PropTypes.object,
};
