import { useTranslation } from "react-i18next";
import { useActionModalGetContext } from "context/ActionModalProvider";
import DetailSection from "../../DetailSection";
import EquipmentUsed from "./EquipmentUsed";
import BulkActionWarning from "../../bulk-action-warning/BulkActionWarning";

const EquipmentUsedWrapper = ({ isInEditMode }) => {
  const { t } = useTranslation();
  const { isBulk } = useActionModalGetContext();

  return (
    <DetailSection title={t("ActionModal.Equipment.Title")}>
      {isBulk ? (
        <BulkActionWarning>
          {t("ActionModal.Equipment.BulkWarning")}
        </BulkActionWarning>
      ) : (
        <EquipmentUsed isInEditMode={isInEditMode} />
      )}
    </DetailSection>
  );
};

export default EquipmentUsedWrapper;
