import clsx from "clsx";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Banner, Loader, NormalText } from "components";
import { FETCH_STATE } from "constants/fetchState";

const OpekepeSyncStep = ({
  index,
  title,
  isDisabled,
  state,
  loadingText,
  errorText,
  successText,
  content = [],
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx("sync-step", {
        disabled: isDisabled,
      })}
    >
      <div className="sync-step-title">
        <span className="sync-step-index">
          <NormalText>{index}</NormalText>
        </span>
        <NormalText>{title}</NormalText>
      </div>
      <div className="sync-step-content">
        {state.state === FETCH_STATE.LOADING && (
          <div className="step-loading">
            <Loader style={{ width: "max-content" }} />
            <NormalText>{loadingText}</NormalText>
          </div>
        )}
        {state.state === FETCH_STATE.ERROR && (
          <>
            <Banner.Error text={errorText} />
            <div className="step-content-row">
              <NormalText>{t("OpekepeSync.DateTime")}</NormalText>
              <NormalText>{moment()?.format("MMMM Do YYYY, h:mm")}</NormalText>
            </div>
          </>
        )}
        {state.state === FETCH_STATE.SUCCESS && (
          <>
            <Banner.Success text={successText} />
            <div className="step-content-row">
              <NormalText>{t("OpekepeSync.DateTime")}</NormalText>
              <NormalText>
                {moment(state.data.dateSynced)?.format("MMMM Do YYYY, h:mm")}
              </NormalText>
            </div>
            {content.map(({ key, title }) => (
              <div className="step-content-row">
                <NormalText>{title}</NormalText>
                <NormalText>{state.data?.[key]}</NormalText>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default OpekepeSyncStep;
