import * as Yup from "yup";
import { yupPassword, yupRepeatPassword } from "./yupRules";
const onlyLatinAndGreekLetters = /^[a-zA-Z\u0370-\u03FF]+$/;

export const registerSchema = (t) =>
  Yup.object().shape({
    firstName: Yup.string()
      .matches(onlyLatinAndGreekLetters, t("Register.OnlyLetters"))
      .required(t("Register.NameError")),
    lastName: Yup.string()
      .matches(onlyLatinAndGreekLetters, t("Register.OnlyLetters"))
      .required(t("Register.LastNameError")),
    // region: Yup.string().when("role", {
    //   is: (value) => value === USER_ROLES.AGRONOMIST,
    //   then: Yup.string().required(t("Register.RegionError")),
    //   otherwise: Yup.string().nullable(),
    // }),
    email: Yup.string()
      .email(t("Register.EmailError"))
      .required(t("Register.EmailRequiredError")),
    password: yupPassword(t),
    repeatPassword: yupRepeatPassword(t),
    term1: Yup.bool().oneOf([true], t("Register.Term1Error")),
    term2: Yup.bool().oneOf([true], t("Register.Term2Error")),
  });
