import { Box, ClickAwayListener, useTheme } from "@mui/material";
import { useSidebarsContext } from "context/SidebarsProvider";
import SideBarContainer from "../../sidebars/SideBarContainer";
import InboxSidebarContainer from "../inbox/InboxSidebarContainer";

const GlobalSidebarsContainer = () => {
  const { selected, closeSidebar } = useSidebarsContext();
  const theme = useTheme();

  const isOpen = Boolean(selected);

  return (
    <>
      <ClickAwayListener onClickAway={closeSidebar}>
        <Box>
          <SideBarContainer
            isOpen={isOpen}
            wrapperStyle={{
              zIndex: theme.zIndex.drawer,
              position: "absolute",
            }}
            style={{ padding: "16px" }}
            size="lg"
            className="global-drawer"
          >
            {selected === "inbox" && <InboxSidebarContainer />}
          </SideBarContainer>
        </Box>
      </ClickAwayListener>
      <div
        className={`global-drawer-background${isOpen ? " open" : ""}`}
        style={{
          zIndex: theme.zIndex.drawerBackground,
        }}
      />
    </>
  );
};

export default GlobalSidebarsContainer;
