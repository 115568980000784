import { useMediaQuery, useTheme } from "@mui/material";
import Background from "assets/images/loginBackground.jpg";
import Logo from "assets/icons/logo-w-text-slogan-green.svg";

const AuthContainer = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div
      style={{
        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        width: "100vw",
        height: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        margin: "0%",
        display: "flex",
        flexDirection: !isMobile ? "row" : "column",
        justifyContent: "space-between",
        alignItems: isMobile && "center",
      }}
    >
      <img
        src={Logo}
        alt="Logo"
        width={!isMobile ? "460px" : "360px"}
        height={!isMobile ? "120px" : "80px"}
        style={{ margin: !isMobile ? "5% 5%" : "10% 5%" }}
      />
      {children}
    </div>
  );
};

export default AuthContainer;
