import { useRef } from "react";
import { Loader } from "components";
import "./verification-code.css";

const VerificationCode = ({ otp, onChange, isLoading, onSubmit }) => {
  const inputRefs = useRef([]);

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    if (!value.length) {
      return;
    }
    if (isNaN(value)) {
      return;
    }
    newOtp[index] = value;
    onChange(newOtp);
    if (index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
    if (newOtp.every((digit) => digit !== "")) {
      onSubmit?.(newOtp);
    }
  };

  const handleCaptureBackspace = (index, e) => {
    if (e.key === "Backspace") {
      if (index > 0) {
        const currValue = otp[index];
        if (!currValue) {
          inputRefs.current[index - 1].focus();
          return;
        }
        const newOtp = [...otp];
        newOtp[index] = "";
        onChange(newOtp);
      }
    }
  };

  return (
    <div className="verification-inputs">
      {otp.map((digit, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={digit}
          onChange={(e) => handleOtpChange(index, e.target.value)}
          onKeyDown={(e) => handleCaptureBackspace(index, e)}
          ref={(el) => (inputRefs.current[index] = el)}
          disabled={isLoading}
        />
      ))}
      {isLoading && <Loader />}
    </div>
  );
};

export default VerificationCode;
