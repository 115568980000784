import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFieldsContextActions } from "./FieldsProvider";
import { paths } from "../utils/paths";
import { api } from "../api";
import { FETCH_STATE } from "constants/fetchState";

const SelectedFieldContext = createContext({
  equipments: [],
  selectedField: undefined,
  equipmentsFetchState: FETCH_STATE.IDLE,
  fetchState: FETCH_STATE.IDLE,
  fetchFieldEquipments: () => {},
});

const SelectedFieldContextActions = createContext({
  selectField: ({ postData, onSuccess, onError }) => {},
  updateSelectedField: ({ id, patchData, onSuccess, onError }) => {},
  deleteSelectedField: ({ id, onSuccess, onError }) => {},
  cancelFieldCollaboration: ({ id, onSuccess, onError }) => {},
});

const SelectedFieldProvider = ({ children }) => {
  const { fieldId } = useParams();
  const navigate = useNavigate();

  const { updateField, deleteField, cancelCollaboration } =
    useFieldsContextActions();

  const [selectedField, setSelectedField] = useState();
  const [fetchState, setFetchState] = useState(FETCH_STATE.IDLE);
  const [equipments, setEquipments] = useState([]);
  const [equipmentsFetchState, setEquipmentsFetchState] = useState(
    FETCH_STATE.IDLE
  );

  const selectField = useCallback(
    (fieldId) => {
      if (fieldId) {
        navigate?.(paths.field(fieldId));
      } else {
        navigate(paths.fields);
      }
    },
    [navigate]
  );

  const fetchSelectedField = useCallback((fieldId) => {
    setFetchState(FETCH_STATE.LOADING);
    api.fields
      .fetchOne({ fieldId })
      .then((res) => {
        setSelectedField(res.data);
        setFetchState(FETCH_STATE.SUCCESS);
      })
      .catch((error) => {
        setFetchState(FETCH_STATE.ERROR);
      });
  }, []);

  const fetchFieldEquipments = useCallback((fieldId) => {
    setEquipmentsFetchState(FETCH_STATE.LOADING);
    api.fields
      .fetchEquipments(fieldId)
      .then((res) => {
        setEquipments(res.data);
        setEquipmentsFetchState(FETCH_STATE.SUCCESS);
      })
      .catch(() => {
        setEquipmentsFetchState(FETCH_STATE.ERROR);
      });
  }, []);

  useEffect(() => {
    if (fieldId) {
      if (
        typeof fetchSelectedField === "function" &&
        typeof fetchFieldEquipments === "function"
      ) {
        fetchSelectedField(fieldId);
        fetchFieldEquipments(fieldId);
      }
    } else {
      setSelectedField();
    }
  }, [fieldId, fetchSelectedField, fetchFieldEquipments]);

  const updateSelectedField = useCallback(
    ({ id, patchData, onSuccess, onError }) => {
      updateField?.({
        id,
        patchData,
        onSuccess: (updatedField) => {
          setSelectedField(updatedField);
          onSuccess?.(updatedField);
        },
        onError,
      });
    },
    [updateField]
  );

  const deleteSelectedField = useCallback(
    ({ id, onSuccess, onError }) => {
      deleteField?.({
        id,
        onSuccess: () => {
          navigate?.(paths.fields);
          onSuccess?.();
        },
        onError,
      });
    },
    [deleteField, navigate]
  );

  const cancelFieldCollaboration = useCallback(
    ({ id, onSuccess, onError }) => {
      cancelCollaboration?.({
        id,
        onSuccess: () => {
          navigate?.(paths.fields);
          onSuccess?.();
        },
        onError,
      });
    },
    [cancelCollaboration, navigate]
  );

  const value = useMemo(
    () => ({
      equipments,
      equipmentsFetchState,
      selectedField,
      fetchState,
    }),
    [selectedField, fetchState, equipments, equipmentsFetchState]
  );

  const actions = useMemo(
    () => ({
      selectField,
      updateSelectedField,
      deleteSelectedField,
      cancelFieldCollaboration,
    }),
    [
      selectField,
      updateSelectedField,
      deleteSelectedField,
      cancelFieldCollaboration,
    ]
  );

  return (
    <SelectedFieldContextActions.Provider value={actions}>
      <SelectedFieldContext.Provider value={value}>
        {children}
      </SelectedFieldContext.Provider>
    </SelectedFieldContextActions.Provider>
  );
};

const useSelectedFieldContext = () => {
  const context = useContext(SelectedFieldContext);

  if (!context) {
    throw new Error(
      "useSelectedFieldContext must be used within a SelectedFieldProvider"
    );
  }
  return useContext(SelectedFieldContext);
};

const useSelectedFieldContextActions = () => {
  const context = useContext(SelectedFieldContextActions);

  if (!context) {
    throw new Error(
      "useSelectedFieldContextActions must be used within a SelectedFieldProvider"
    );
  }
  return useContext(SelectedFieldContextActions);
};

export {
  useSelectedFieldContext,
  useSelectedFieldContextActions,
  SelectedFieldProvider,
};
