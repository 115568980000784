import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormNumberInput, FormSelectInput } from "../../form";
import SubTitle from "../Typography/SubTitle";
import NormalText from "../Typography/NormalText";

const AnalysisFields = ({ analysisFields = {}, isViewOnly }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");

  const analysisValues = useMemo(() => {
    return Object.entries(analysisFields).map(([key, field]) => ({
      ...field,
      key,
    }));
  }, [analysisFields]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        overflow: "auto",
      }}
    >
      <div style={{ width: "100%" }}>
        {analysisValues.map((value, index) => (
          <div
            key={value.symbol}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "4px 12px",
              alignItems: "baseline",
              backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#FFFFFF",
              ...(index < analysisValues.length - 1 && {
                borderBottom: "1px solid #e1e1e1",
              }),
            }}
          >
            <SubTitle text={value.symbol} />
            <NormalText text={isEn ? value.nameEn : value.nameGr} />
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              {isViewOnly ? (
                <NormalText text={value.quantity} />
              ) : (
                <FormNumberInput
                  name={`diagnoseAction.analysis.${value.key}.quantity`}
                  style={{ maxWidth: "100px" }}
                />
              )}
              {isViewOnly ? (
                <NormalText
                  style={{ width: "100px" }}
                  text={t(`OperationUnits.${value?.quantityUnit}`)}
                />
              ) : value.quantityUnits.length <= 1 ? (
                <NormalText
                  style={{ width: "100px" }}
                  text={t(`OperationUnits.${value.quantityUnits[0]}`)}
                />
              ) : (
                <FormSelectInput
                  name={`diagnoseAction.analysis.${value.key}.quantityUnit`}
                  style={{ minWidth: "100px", fontSize: "14px" }}
                  options={value.quantityUnits.map((unit) => ({
                    value: unit,
                    label: t(`OperationUnits.${unit}`),
                  }))}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default AnalysisFields;
