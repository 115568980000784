import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { paths } from "utils/paths";
import { api } from "api";
import { useTranslation } from "react-i18next";
import { toaster } from "utils/toaster";

const sortCropsAndAreas = (arr) => {
  const arrSorted = arr
    .map((crop) => {
      const childrenSorted = crop.children.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      return { ...crop, children: childrenSorted };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  return arrSorted;
};

const findAllCropsOfFields = (fields, isEn) => {
  const crops = fields.reduce((acc, field) => {
    const cropId = field.varietyRead.cropId;
    const varietyId = field.varietyRead.varietyId;
    const varietyName = isEn
      ? field.varietyRead.varietyNameEn
      : field.varietyRead.varietyNameGr;

    const existingCrop = acc.find((crop) => crop.value === cropId);
    if (existingCrop) {
      const existingVariety = existingCrop.children.find(
        (variety) => variety.value === varietyId
      );

      if (existingVariety) {
        return acc;
      } else {
        const variety = {
          value: varietyId,
          label: varietyName,
          parentIdPath: [cropId],
        };
        existingCrop.children.push(variety);
        return acc;
      }
    } else {
      const cropName = isEn
        ? field.varietyRead.cropNameEn
        : field.varietyRead.cropNameGr;

      const crop = {
        value: cropId,
        label: cropName,
        children: [
          {
            value: varietyId,
            label: varietyName,
            parentIdPath: [cropId],
          },
        ],
        parentIdPath: [],
      };
      return [...acc, crop];
    }
  }, []);

  const cropsSorted = sortCropsAndAreas(crops);

  return cropsSorted;
};

const findAllAreasOfFields = (fields, isEn) => {
  const areas = fields.reduce((acc, field) => {
    const regionId = field.municipality.regionId;
    const prefectureId = field.municipality.prefectureId;
    const prefectureNameGr = field.municipality.prefectureNameGr;
    const prefectureNameEn = field.municipality.prefectureNameEn;

    const existingArea = acc.find((area) => area.value === regionId);
    if (existingArea) {
      const existingPrefecture = existingArea.children.find(
        (prefecture) => prefecture.value === prefectureId
      );
      if (existingPrefecture) {
        return acc;
      } else {
        const prefecture = {
          value: prefectureId,
          label: isEn ? prefectureNameEn : prefectureNameGr,
          parentIdPath: [regionId],
        };
        existingArea.children.push(prefecture);
        return acc;
      }
    } else {
      const regionNameGr = field.municipality.regionNameGr;
      const regionNameEn = field.municipality.regionNameEn;
      const area = {
        value: regionId,
        label: isEn ? regionNameEn : regionNameGr,
        children: [
          {
            value: prefectureId,
            label: isEn ? prefectureNameEn : prefectureNameGr,
            parentIdPath: [regionId],
          },
        ],
        parentIdPath: [],
      };
      return [...acc, area];
    }
  }, []);

  const areasSorted = sortCropsAndAreas(areas);
  return areasSorted;
};

const FieldsContext = createContext({
  fields: [],
  fetchState: {
    isLoading: false,
    error: undefined,
  },
  myLocations: [],
  myCrops: [],
});

const FieldsContextActions = createContext({
  fetchUserFields: ({ onSuccess, onError }) => {},
  createNewField: ({ postData, onSuccess, onError }) => {},
  updateField: ({ id, patchData, onSuccess, onError }) => {},
  deleteField: ({ id, onSuccess, onError }) => {},
  cancelCollaboration: ({ id, onSuccess, onError }) => {},
});

const FieldsProvider = ({ children }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");

  const [fields, setFields] = useState([]);
  const [fetchState, setFetchState] = useState({
    isLoading: false,
    error: undefined,
  });

  const fetchUserFields = useCallback(
    (handlers = { onSuccess: null, onError: null }) => {
      const { onSuccess, onError } = handlers;

      setFetchState({ isLoading: true, error: undefined });
      api.fields
        .fetchMy()
        .then((res) => {
          const fields = res.data.fields;
          setFields(fields);

          setFetchState({ isLoading: false, error: undefined });
          onSuccess?.(fields);
        })
        .catch((error) => {
          setFetchState({ isLoading: false, error });
          onError?.(error);
        });
    },
    []
  );

  const myCrops = useMemo(() => {
    const crops = findAllCropsOfFields(fields, isEn);
    return crops;
  }, [fields, isEn]);

  const myLocations = useMemo(() => {
    const areas = findAllAreasOfFields(fields, isEn);
    return areas;
  }, [fields, isEn]);

  useEffect(() => {
    if (typeof fetchUserFields === "function") {
      fetchUserFields();
    }
  }, [fetchUserFields]);

  const createNewField = useCallback(
    ({ postData, onSuccess, onError }) => {
      api.fields
        .create(postData)
        .then(({ data: field }) => {
          navigate?.(paths.field(field.id));
          onSuccess?.(field);
          fetchUserFields?.();
        })
        .catch((error) => {
          const { response } = error || {};
          if (response?.status === 400) {
            const { coordinates, area } = response?.data || {};
            if (
              coordinates?.find((err) => err.includes("intersect any land"))
            ) {
              toaster.error({
                title: t("FieldsPage.AddFieldSidebar.Error.Creating.Title"),
                message: t("FieldsPage.AddFieldSidebar.Error.Creating.InWater"),
              });
            } else if (
              coordinates?.find((err) => err.includes("not in greece"))
            ) {
              toaster.error({
                title: t("FieldsPage.AddFieldSidebar.Error.Creating.Title"),
                message: t(
                  "FieldsPage.AddFieldSidebar.Error.Creating.NotInGreece"
                ),
              });
            } else if (area?.find((err) => err.includes("too big"))) {
              toaster.error({
                title: t("FieldsPage.AddFieldSidebar.Error.Creating.Title"),
                message: t(
                  "FieldsPage.AddFieldSidebar.Error.Creating.AreaTooBig"
                ),
              });
            } else {
              toaster.error({
                title: t("FieldsPage.AddFieldSidebar.Error.Creating.Title"),
                message: t("FieldsPage.AddFieldSidebar.Error.Creating.Unkown"),
              });
            }
          }
          onError?.(error);
        });
    },
    [fetchUserFields, navigate, t]
  );

  const updateField = useCallback(
    ({ id, patchData, onSuccess, onError }) => {
      api.fields
        .update(id, patchData)
        .then((res) => {
          onSuccess?.(res.data);
          setFields((prev) =>
            prev.map((field) => {
              if (field.id === id) {
                return res.data;
              }
              return field;
            })
          );
        })
        .catch((error) => {
          const { response } = error || {};
          if (response?.status === 400) {
            const { coordinates, area } = response?.data || {};
            if (
              coordinates?.find((err) => err.includes("intersect any land"))
            ) {
              toaster.error({
                title: t("FieldsPage.AddFieldSidebar.Error.Updating.Title"),
                message: t("FieldsPage.AddFieldSidebar.Error.Creating.InWater"),
              });
            } else if (
              coordinates?.find((err) => err.includes("not in greece"))
            ) {
              toaster.error({
                title: t("FieldsPage.AddFieldSidebar.Error.Creating.Title"),
                message: t(
                  "FieldsPage.AddFieldSidebar.Error.Creating.NotInGreece"
                ),
              });
            } else if (area?.find((err) => err.includes("too big"))) {
              toaster.error({
                title: t("FieldsPage.AddFieldSidebar.Error.Creating.Title"),
                message: t(
                  "FieldsPage.AddFieldSidebar.Error.Creating.AreaTooBig"
                ),
              });
            } else {
              toaster.error({
                title: t("FieldsPage.AddFieldSidebar.Error.Creating.Title"),
                message: t("FieldsPage.AddFieldSidebar.Error.Creating.Unkown"),
              });
            }
          }
          onError?.(error);
        });
    },
    [t]
  );

  const deleteField = useCallback(
    ({ id, onSuccess, onError }) => {
      api.fields
        .delete(id)
        .then((res) => {
          onSuccess?.(res.data);
          fetchUserFields?.();
        })
        .catch((err) => onError?.(err));
    },
    [fetchUserFields]
  );

  const cancelCollaboration = useCallback(
    ({ id, onSuccess, onError }) => {
      api.fields
        .removeMe(id)
        .then((res) => {
          onSuccess?.(res.data);
          fetchUserFields?.();
        })
        .catch((err) => onError?.(err));
    },
    [fetchUserFields]
  );

  const value = useMemo(
    () => ({
      fields,
      fetchState,
      myCrops,
      myLocations,
    }),
    [fields, fetchState, myCrops, myLocations]
  );

  const actions = useMemo(
    () => ({
      fetchUserFields,
      createNewField,
      updateField,
      deleteField,
      cancelCollaboration,
    }),
    [
      fetchUserFields,
      createNewField,
      updateField,
      deleteField,
      cancelCollaboration,
    ]
  );

  return (
    <FieldsContextActions.Provider value={actions}>
      <FieldsContext.Provider value={value}>{children}</FieldsContext.Provider>
    </FieldsContextActions.Provider>
  );
};

const useFieldsContext = () => {
  const context = useContext(FieldsContext);

  if (!context) {
    throw new Error("useFieldsContext must be used within a FieldsProvider");
  }
  return useContext(FieldsContext);
};

const useFieldsContextActions = () => {
  const context = useContext(FieldsContextActions);

  if (!context) {
    throw new Error(
      "useFieldsContextActions must be used within a FieldsProvider"
    );
  }
  return useContext(FieldsContextActions);
};

export { useFieldsContext, useFieldsContextActions, FieldsProvider };
