import { NormalText } from "components";
import PropTypes from "prop-types";

const TreeNode = ({
  node,
  onToggle,
  onCheck,
  checkedNodes,
  className,
  isDisabled,
  style = {},
}) => {
  const hasChildren = node.children && node.children.length > 0;
  const isChecked = checkedNodes.includes(node.value);
  const level = node.parentIdPath.length;

  return (
    <div
      className="tree-node"
      style={{
        paddingLeft: `${level * 20}px`,
      }}
    >
      {hasChildren && (
        <button
          className="btn-unstyled expand-icon"
          onClick={() => onToggle(node)}
        >
          {node.isExpanded ? "-" : "+"}
        </button>
      )}
      <input
        type="checkbox"
        disabled={isDisabled}
        checked={isChecked}
        onChange={() => onCheck(node)}
        className={hasChildren ? className : `no-children ${className}`}
        style={style}
      />
      <NormalText>{node.label}</NormalText>
    </div>
  );
};

export default TreeNode;

const nodePropType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  parentIdPath: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool,
});
nodePropType.children = PropTypes.arrayOf(nodePropType);

export const treeNodePropType = PropTypes.shape({
  node: nodePropType,
  onToggle: PropTypes.func,
  onCheck: PropTypes.func,
  isDisabled: PropTypes.bool,
  checkedNodes: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  style: PropTypes.object,
});

TreeNode.propTypes = treeNodePropType;
