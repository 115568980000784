import { useState, useRef, useEffect } from "react";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import { Polygon, useMap } from "react-leaflet";
import L from "leaflet";
import { useAddFieldsContext } from "../../context/AddFieldProvider";

const polygonStyles = {
  templineColor: "#3c8cff",
  hintLineColor: "green",
  lineColor: "#ffe599",
  fillColor: "white",
  editedFillColor: "red",
};

const drawingOptions = {
  snappable: true,
  snapDistance: 20,
  allowSelfIntersection: false,
  continueDrawing: true,
  hintlineStyle: { color: polygonStyles.hintLineColor, dashArray: [5, 5] },
  templineStyle: { color: polygonStyles.templineColor },
  pathOptions: {
    color: polygonStyles.lineColor,
    fillColor: polygonStyles.fillColor,
    fillOpacity: 0.3,
  },
};

const MapWithGeoman = () => {
  const {
    polygon,
    setPolygon,
    kaekPolygon,
    fieldFocused,
    deletedPolygon,
    isDrawOnMapOn,
    deleteAllPolygons,
    setDeleteAllPolygons,
  } = useAddFieldsContext();

  const map = useMap();

  const [idCounter, setIdCounter] = useState(0);

  const polygonRef = useRef();
  const stateRef = useRef();
  stateRef.current = idCounter;
  polygonRef.current = polygon;

  //----------------------------FUNCTIONS-----------------------------------------//
  const handlePolygon = (polygon) => {
    const feature = polygon.toGeoJSON();
    const coords = feature.geometry.coordinates;
    const newCoords = L.GeoJSON.coordsToLatLngs(...coords);

    setPolygon({ id: stateRef.current, coords: newCoords });
  };
  const onPolygonAdded = (polygon) => {
    handlePolygon(polygon);
    setIdCounter(stateRef.current + 1);
  };

  const onPolygonEdited = (polygon) => {
    handlePolygon(polygon);
  };

  //----------------------------EVENT HANDLING----------------------------------------//

  // If one polygon is drawn stop drawing more
  useEffect(() => {
    if (idCounter >= 1) {
      map.pm.disableDraw();
    }
  }, [idCounter]);

  useEffect(() => {
    // map.setView(center, zoom);
    map.pm.setGlobalOptions({ pmIgnore: false });

    map.pm.enableDraw("Polygon", drawingOptions);

    map.on("pm:drawstart", (e) => {});

    map.on("pm:create", (e) => {
      if (e.layer && e.layer.pm) {
        const polygon = e;
        polygon.layer.identifierValue = stateRef.current;
        onPolygonAdded(polygon.layer);
        //Enabling Editing after creation
        polygon.layer.pm.enable();
      }
    });
  }, [map]);

  useEffect(() => {
    if (!map?.pm) return;

    if (isDrawOnMapOn) {
      map.pm.enableDraw("Polygon", drawingOptions);
    } else {
      map.pm.disableDraw();
    }
  }, [isDrawOnMapOn, map.pm]);

  useEffect(() => {
    map.eachLayer((layer) => {
      if (layer.pm) {
        if (layer instanceof L.Polygon) {
          layer.on("pm:edit", (e) => {
            const polygon = e;
            onPolygonEdited(polygon.layer);
          });
          if (layer.identifierValue === fieldFocused) {
            map.flyToBounds(layer.getBounds());
            layer.setStyle({ fillColor: polygonStyles.editedFillColor });
          } else {
            layer.setStyle({ fillColor: polygonStyles.fillColor });
          }

          if (layer.identifierValue === deletedPolygon) {
            //maping the layers and remove the polygon that has clicked in SideBar
            map.removeLayer(layer);
          }
        }
      }
    });
  }, [fieldFocused, deletedPolygon, polygon]);

  useEffect(() => {
    if (!deleteAllPolygons) return;
    map.eachLayer((layer) => {
      if (layer.pm) {
        if (layer instanceof L.Polygon) {
          if (deleteAllPolygons) {
            //maping the layers and remove the polygon that has clicked in SideBar
            map.removeLayer(layer);
          }
        }
      }
    });
    setDeleteAllPolygons(false);
  }, [polygon, deleteAllPolygons]);

  useEffect(() => {
    if (kaekPolygon?.length > 0 && map) {
      map.fitBounds(kaekPolygon);

      map.eachLayer((layer) => {
        if (layer.pm) {
          if (layer instanceof L.Polygon) {
            layer.pm.disable();
          }
        }
      });

      map.pm.disableDraw();
    } else {
      if (!deleteAllPolygons) return;

      map.eachLayer((layer) => {
        if (layer.pm) {
          if (layer instanceof L.Polygon) {
            if (deleteAllPolygons) {
              //maping the layers and remove the polygon that has clicked in SideBar
              map.removeLayer(layer);
            }
          }
        }
      });
      setDeleteAllPolygons(false);
    }
  }, [kaekPolygon, deleteAllPolygons]);

  return kaekPolygon?.length > 0 ? (
    <Polygon
      positions={kaekPolygon}
      pathOptions={{
        color: "#ffe599",
        fillColor: "#4a437d",
        fillOpacity: 0.1,
      }}
    />
  ) : (
    <></>
  );
};

export default MapWithGeoman;
