export const OPEKEPE_SYNC_SCOPE = {
  FARMER: "farmer",
  KYD: "kyd",
};

export const SYNC_FIELDS_STATUS = {
  DOWNLOADED: "downloaded",
  SYNCED: "synced",
  FAILED: "failed",
  CONFLICT: "conflict",
};
export const SYNC_FIELDS_STATUS_DICT = {
  1: "downloaded",
  2: "synced",
  4: "failed",
  5: "conflict",
};
