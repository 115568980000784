import axiosInstance from "../api/utils/axiosInstance";

const saveLanguagePreference = (language) => {
  localStorage.setItem("language", language);
};

const saveOpekepeRedirectUrl = (path) => {
  localStorage.setItem("opekepeRedirectUrl", path);
};

const getOpekepeRedirectUrl = () => {
  const url = localStorage.getItem("opekepeRedirectUrl");
  localStorage.removeItem("opekepeRedirectUrl");
  return url;
};

const getLanguagePreference = () => {
  return localStorage.getItem("i18nextLng");
};

const removeLanguagePreference = () => {
  localStorage.removeItem("i18nextLng");
};

const setCredsInLocalStorage = ({ token, refresh, roles, domains }) => {
  axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
  localStorage.setItem("access", JSON.stringify(token));
  localStorage.setItem("refresh", JSON.stringify(refresh));
  localStorage.setItem("roles", JSON.stringify(roles));
  localStorage.setItem("domains", JSON.stringify(domains));
};

const removeCredsFromLocalStorage = () => {
  delete axiosInstance.defaults.headers.common["Authorization"];
  localStorage.removeItem("access");
  localStorage.removeItem("refresh");
  localStorage.removeItem("roles");
  localStorage.removeItem("domains");
};

const getCredsFromLocalStorage = () => ({
  token: JSON.parse(localStorage.getItem("access")),
  refresh: JSON.parse(localStorage.getItem("refresh")),
  roles: JSON.parse(localStorage.getItem("roles")),
  domains: JSON.parse(localStorage.getItem("domains")),
});

const saveOpekepeLoginCode = (code) => {
  localStorage.setItem("opekepeLoginCode", code);
};
const readOpekepeLoginCode = () => {
  return localStorage.getItem("opekepeLoginCode");
};
const getOpekepeLoginCode = () => {
  const code = localStorage.getItem("opekepeLoginCode");
  localStorage.removeItem("opekepeLoginCode");
  return code;
};

const saveSyncedUser = (user) => {
  localStorage.setItem("opekepeSyncUser", JSON.stringify(user));
};
const readSyncedUser = () => {
  let user = localStorage.getItem("opekepeSyncUser");
  user = JSON.parse(user);
  return user;
};
const getSyncedUser = () => {
  let user = localStorage.getItem("opekepeSyncUser");
  user = JSON.parse(user);
  localStorage.removeItem("opekepeSyncUser");
  return user;
};

export const storage = {
  saveLanguagePreference,
  saveOpekepeRedirectUrl,
  getOpekepeRedirectUrl,
  getLanguagePreference,
  removeLanguagePreference,
  setCredsInLocalStorage,
  removeCredsFromLocalStorage,
  getCredsFromLocalStorage,
  saveOpekepeLoginCode,
  getOpekepeLoginCode,
  readOpekepeLoginCode,
  saveSyncedUser,
  readSyncedUser,
  getSyncedUser,
};
