import { USER_ROLES } from "../../constants/userRoles";
import { storage } from "../../utils/localStorage";
import axiosInstance from "../utils/axiosInstance";
import { URLS } from "../utils/baseUrls";

const userRegister = ({ user, role }) => {
  let userRole;
  if (role === USER_ROLES.FARMER) {
    userRole = "farmer";
  } else if (role === USER_ROLES.AGRONOMIST) {
    userRole = "agronomist";
  }
  return axiosInstance.post(`${URLS.users}/${userRole}/register/`, user);
};

const verifyToken = () => {
  const { token } = storage.getCredsFromLocalStorage();
  return axiosInstance.post(`${URLS.auth}/verify/`, { token });
};

const refreshToken = () => {
  const { refresh } = storage.getCredsFromLocalStorage();
  return axiosInstance.post(`${URLS.auth}/refresh/`, { refresh });
};

/**
 * @param {Object} data - The login data.
 * @param {string} data.email - The user's email.
 * @param {string} data.password - The user's password.
 */
const login = (data) => axiosInstance.post(`${URLS.auth}/`, data);

const logout = () => {
  const { refresh } = storage.getCredsFromLocalStorage();
  return axiosInstance.post(`${URLS.auth}/blacklist/`, { refresh });
};

const otpRequest = (payload) => {
  return axiosInstance.post(`${URLS.users}/otp/create-otp/`, payload);
};

const otpVerification = (otp) => {
  return axiosInstance.get(`${URLS.users}/otp/verify-otp/?code=${otp}`);
};

export const authApi = {
  register: userRegister,
  login,
  logout,
  verifyToken,
  refreshToken,
  otpRequest,
  otpVerification,
};
