import { useFormikContext } from "formik";
import { useActionChoicesContext } from "context/GeneralAgroProvider";
import { useActionModalGetContext } from "context/ActionModalProvider";
import { ACTION_STATUS } from "constants/actionStatus";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import WateringSuggestion from "./WateringSuggestion";
import WateringPerformed from "./WateringPerformed";

const WateringDetails = ({ viewMode, status }) => {
  const { values } = useFormikContext();
  const { actionChoices } = useActionChoicesContext();
  const { fieldInfo } = useActionModalGetContext();
  const { operations } = actionChoices?.actions?.watering;

  const operationTypeId = fieldInfo.wateringMechanismRead?.id;

  const isPerformedWithSuggested =
    Boolean(values?.suggestedActionId) ||
    (values.status === ACTION_STATUS.performed &&
      values.initialStatus !== ACTION_STATUS.performed);

  if (
    viewMode === ACTION_VIEW_MODE.view ||
    viewMode === ACTION_VIEW_MODE.edit
  ) {
    if (status !== ACTION_STATUS.performed) {
      return (
        <WateringSuggestion
          viewMode={viewMode}
          isFullWidth
          operationTypeId={operationTypeId}
          operations={operations}
        />
      );
    } else if (isPerformedWithSuggested) {
      return (
        <div className="action-details-both-box-wrapper">
          <WateringSuggestion
            viewMode={ACTION_VIEW_MODE.view}
            operationTypeId={operationTypeId}
            operations={operations}
            isProposedValue
          />
          <WateringPerformed
            viewMode={viewMode}
            operationTypeId={operationTypeId}
            operations={operations}
          />
        </div>
      );
    } else {
      return (
        <WateringPerformed
          viewMode={viewMode}
          isFullWidth
          operationTypeId={operationTypeId}
          operations={operations}
        />
      );
    }
  } else if (viewMode === ACTION_VIEW_MODE.new) {
    if (status !== ACTION_STATUS.performed) {
      return (
        <WateringSuggestion
          viewMode={viewMode}
          isFullWidth
          operationTypeId={operationTypeId}
          operations={operations}
        />
      );
    } else {
      return (
        <WateringPerformed
          viewMode={viewMode}
          isFullWidth
          operationTypeId={operationTypeId}
          operations={operations}
        />
      );
    }
  } else {
    return <></>;
  }
};

export default WateringDetails;
