import {
  useActionModalGetContext,
  useActionModalSetContext,
} from "context/ActionModalProvider";
import { ActionModal } from "components";

const BulkActionModalContainer = ({ selectedFields = [] }) => {
  const { closeActionModal } = useActionModalSetContext();
  const { isOpen } = useActionModalGetContext();

  return (
    isOpen && (
      <ActionModal
        isOpen={isOpen}
        selectedFields={selectedFields}
        onClose={closeActionModal}
      />
    )
  );
};

export default BulkActionModalContainer;
