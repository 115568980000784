import clsx from "clsx";
import { Modal as MuiModal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NormalText from "../Typography/NormalText";
import "./modal.css";

const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  style = {},
  styles = {},
  className,
  classNames = {},
}) => (
  <MuiModal open={isOpen} onClose={onClose}>
    <div className={`modal-component-wrapper ${className || ""}`} style={style}>
      <div className="modal-component-title">
        <NormalText className="modal-component-title-text">
          {title || ""}
        </NormalText>
        <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
      </div>
      <div
        className={clsx("modal-component-body", classNames.body)}
        style={styles.body}
      >
        {children}
      </div>
    </div>
  </MuiModal>
);

export default Modal;
