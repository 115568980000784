import { Typography, styled } from "@mui/material";

const TypographyStyled = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "14.06px",
  color: "#43434a",
}));

const SmallText = ({ text, style = {}, className, children }) => (
  <TypographyStyled className={className} sx={style}>
    {typeof text !== "undefined" ? text : children}
  </TypographyStyled>
);

export default SmallText;
