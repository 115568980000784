import { useCallback, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { api } from "api";
import { Loader, SmallText } from "components";
import { storage } from "utils/localStorage";

const KYD_SYNC_STATUS = {
  FAILED: "failed",
  SUCCESS: "connected",
  NOT_FOUND: "not_found",
};

const SyncKydLinkSection = ({ userId, opekepeLink, onSync }) => {
  const { t } = useTranslation();
  const pollingRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const fetchSyncStatus = useCallback(async () => {
    if (!userId) {
      return;
    }

    const { data, error } = await api.opekepe.checkSyncUser(userId);
    if (error) {
      console.error(error);
      return;
    }
    if (data.status !== KYD_SYNC_STATUS.SUCCESS) {
      return;
    }

    setIsLoading(true);
    const { data: accountData, error: accountError } =
      await api.opekepe.getSyncedAccount(userId);
    if (accountData) {
      storage.saveSyncedUser({ id: userId });
    }

    onSync?.({ data: accountData, error: accountError });
    clearInterval(pollingRef.current);
    setIsLoading(false);
  }, [userId, onSync]);

  useEffect(() => {
    if (typeof fetchSyncStatus === "function") {
      pollingRef.current = setInterval(() => {
        fetchSyncStatus();
      }, 3000);
    }

    return () => {
      if (pollingRef.current) clearInterval(pollingRef.current);
    };
  }, [fetchSyncStatus]);

  return (
    <div>
      {isLoading ? (
        <Loader size="40px" />
      ) : (
        <>
          <SmallText style={{ fontStyle: "italic", marginBottom: "1rem" }}>
            {t("OpekepeSync.IcognitoBtn.Note")}
          </SmallText>
          <a
            href={opekepeLink}
            target="_blank"
            rel="noreferrer noopener"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {t("OpekepeSync.IcognitoBtn.Title")}
          </a>
          <SmallText style={{ fontSize: "13px", marginTop: "1rem" }}>
            <Trans i18nKey="OpekepeSync.IcognitoBtn.Subtitle" />
          </SmallText>
          <br />
          <SmallText>{t("OpekepeSync.IcognitoBtn.Disclaimer")}</SmallText>
        </>
      )}
    </div>
  );
};
export default SyncKydLinkSection;
