import { FormControlLabel, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { ACTION_STATUS } from "constants/actionStatus";
import LoadingButton from "../../../../LoadingButton/LoadingButton";

const NewActionModalHeader = ({
  action,
  onSubmit,
  isLoading,
  isToggleHidden,
}) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();

  const { initialStatus } = action;

  const handleStatusChange = (e) => {
    setFieldValue(
      "status",
      e.target.checked ? ACTION_STATUS.performed : ACTION_STATUS.suggested
    );
  };

  return (
    <div className="action-modal-header-row space-between">
      <div className="action-modal-header-title">
        {t("ActionModal.Mode.NewAction")}
      </div>
      <div className="action-modal-header-btn-group">
        {!isToggleHidden && (
          <FormControlLabel
            control={
              <Switch
                defaultChecked={
                  initialStatus && initialStatus === ACTION_STATUS.performed
                }
                onChange={handleStatusChange}
              />
            }
            label={t("ActionModal.PerformedAction")}
          />
        )}
        <LoadingButton
          isLoading={isLoading}
          isDisabled={isLoading}
          className="action-action-button"
          onClick={onSubmit}
          buttonType="filled"
        >
          {t("ActionModal.SubmitButton")}
        </LoadingButton>
      </div>
    </div>
  );
};
export default NewActionModalHeader;
