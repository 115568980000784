export const ACTION = {
  watering: "watering",
  drug: "drug",
  fertilize: "fertilize",
  diagnose: "diagnose",
  other: "other",
};

export const OPERATIONS = {
  WATERING: {
    SPRINKLERS: "sprinklers", // Αυτοπροωθούμενα συστήματα καταιονισμού
    SUB_SURFACE_IRRIGATION: "subSurfaceIrrigation", // Υπόγειοι συστήματα άρδευσης
    DROP_WATERING: "dropWatering", // Πότισμα στάγδην
    ARTIFICIAL_RAIN: "artificialRain",
    DROP_MINI_SPRINKLERS: "dropMiniSprinklers", // Μικροεκτοξευτήρες
    SURFACE_IRRIGATION: "surfaceIrrigation", // Επιφανειακή άρδευσης
    OTHER: "other",
  },
  FERTILIZE: {
    HYDROLUBRICATION: "hydrolubrication", // Υδρολίπανση
    SOIL_BASIC: "soilBasic", // Λίπανση εδάφους
    SOIL_SURFACE: "soilSurface", // Λίπανση επιφάνειας εδάφους
    FOLIAR: "foliar", // Φυλλική λίπανση
  },
  DIAGNOSE: {
    ON_SITE_TESTING: "onSiteTesting", // Επιτόπιος διαγνωστικός έλεγχος
    SOIL_SAMPLING: "soilSampling", // Δειγματοληψία εδάφους
    LEAF_ANALYSIS: "leafAnalysis", // Φυλλοδιαγνωστική ανάλυση
    INSECT_MONITORING: "insectMonitoring", // Παρακολούθηση εντόμων
    LAB: "lab", // Εργαστηριακός έλεγχος
  },
  OTHER: {
    BUNDLING: "bundling", // Δεματοποίηση
    ERADICATION: "eradication", // Εκρίζωση
    GATHERING: "gathering", // Συγκομιδή
    PLANTING: "planting", // Φύτευση
    PLOUGHING: "ploughing", // Όργωμα
    PRUNNING: "prunning", // Κλάδεμα
    REPRODUCTIVE_MATERIAL: "reproductiveMaterial", // Αναπαραγωγικό υλικό
    SILAGE: "silage", // Ενσίρωση
    SOWING: "sowing", // Σπορά
    STIRRING: "stirring", // Ανάδευση
    SUN_SOLARIZATION: "sunSolarization", // Ηλιοαπολύμανση
    WRAPPING: "wrapping", // Περιτύλιξη δεμάτων
  },
};
