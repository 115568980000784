import { useCallback, useState } from "react";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import FieldMapContainer from "./FieldMapContainer";
import MapToolPosition from "./MapToolPosition";
import AnalysisMarker from "./markers/AnalysisMarker";
import MapPolygon from "./MapPolygon";
import BeingPlacedAnalysisMarker from "./markers/BeingPlacedAnalysisMarker";
import addMarker from "../../assets/icons/map/add-marker.svg";
import FieldAnalysisHelperOverlay from "./FieldAnalysisHelperOverlay";
import { useTranslation } from "react-i18next";

const FieldAnalysisSamples = ({
  center,
  latlngCoords,
  samples = [],
  onSamplesChange,
  isViewMode,
}) => {
  const { t } = useTranslation();
  const [isAddingMarker, setIsAddingMarker] = useState(false);

  const handleClick = () => {
    // TODO: handle add or cancel add
    setIsAddingMarker((prev) => !prev);
  };

  const handleDelete = useCallback(
    (index) => {
      if (typeof onSamplesChange === "function") {
        const newSamples = [...samples];
        newSamples.splice(index, 1);

        onSamplesChange(newSamples);
      }
    },
    [onSamplesChange, samples]
  );

  const handleMarkerPlaced = (position) => {
    if (typeof onSamplesChange === "function") {
      onSamplesChange([...samples, position]);
    }
    setIsAddingMarker(false);
  };

  const handlePositionChange = useCallback(
    (latlng, index) => {
      if (typeof onSamplesChange === "function") {
        const newSamples = [...samples];
        newSamples[index] = [latlng.lat, latlng.lng];

        onSamplesChange(newSamples);
      }
    },
    [onSamplesChange, samples]
  );

  return (
    latlngCoords &&
    center && (
      <FieldMapContainer center={center} staticMap>
        <MapPolygon LatLngCoords={latlngCoords} />
        {!isViewMode && (
          <>
            <MapToolPosition style={{ top: 10, right: 10 }}>
              <ButtonCustom
                onClick={handleClick}
                buttonType="filled"
                style={{
                  border: 0,
                  backgroundColor: "#fefefecc",
                  color: "#000",
                  "&:hover": {
                    border: 0,
                    backgroundColor: !isAddingMarker && "#ffffff",
                  },
                  "&.Mui-disabled": {
                    border: 0,
                    backgroundColor: "#fefefeaa",
                  },
                }}
                disabled={isAddingMarker}
                startIcon={
                  <img
                    src={addMarker}
                    alt="add-marker"
                    style={{ width: "20px" }}
                  />
                }
              >
                {t("Global.Add")}
              </ButtonCustom>
            </MapToolPosition>
            <MapToolPosition style={{ top: 10, left: 10 }}>
              <FieldAnalysisHelperOverlay />
            </MapToolPosition>
          </>
        )}
        {samples.map((sample, index) => (
          <AnalysisMarker
            key={JSON.stringify(sample)}
            position={sample}
            onPositionChange={(latlng) => handlePositionChange(latlng, index)}
            isViewOnly={isViewMode}
            onDelete={() => handleDelete(index)}
          />
        ))}
        {isAddingMarker && (
          <BeingPlacedAnalysisMarker onMarkerPlaced={handleMarkerPlaced} />
        )}
      </FieldMapContainer>
    )
  );
};

export default FieldAnalysisSamples;
